import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonList,
    IonItem,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonNote,
    IonButton,
    IonIcon,
    IonRefresher,
    IonRefresherContent, IonLoading,
} from '@ionic/react';
import React, {useCallback, useEffect, useState} from "react";
import {Storage} from "@capacitor/storage";
import {formatDate} from "../services/date";
import {arrowBackOutline, eye} from "ionicons/icons";
import {useFetch} from "../hooks/use-fetch";
import {useHistory} from "react-router";

const Messages: React.FC = () => {
    const [token, setToken] = useState('');
    const [messages, setMessages] = useState({read: [], unread: []});
    const [selectedSegment, setSelectedSegment] = useState('unread');
    const {loading, fetchData, getMessages} = useFetch();
    const history = useHistory();

    const refreshData = useCallback(async (event?) => {
        if (token) {
            const messages = await getMessages(JSON.stringify({token}));
            if (messages) {
                setMessages(messages);
            }
        }
        if (event) {
            event.detail.complete();
        }
    }, [token, getMessages]);

    const prevPage = () => {
        history.replace(`/`);
    };

    useEffect(() => {
        Storage.get({key: 'user'}).then(async (res) => {
            if (res.value) {
                const {token} = await JSON.parse(res.value);

                setToken(token);
                await refreshData();
            }
        });
    }, [refreshData]);

    const markRead = async (message_id) => {
        await fetchData('ZidaMarkMessageAsRead', 'POST', JSON.stringify({token: token, message_id}));
        await refreshData();
    };

    const readAll = async () => {
        await fetchData('ZidaMarkAllMessagesAsRead', 'POST', JSON.stringify({token}));
        await refreshData();
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={prevPage}><IonIcon icon={arrowBackOutline} slot="icon-only"/></IonButton>
                    </IonButtons>
                    {selectedSegment === 'unread' && (
                      <IonButtons slot="end">
                          <IonButton onClick={readAll}>Alles gelezen</IonButton>
                      </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonRefresher onIonRefresh={refreshData} slot="fixed">
                    <IonRefresherContent/>
                </IonRefresher>
                <IonSegment value={selectedSegment} onIonChange={(event) => setSelectedSegment(event.detail.value)}>
                    <IonSegmentButton value="unread">
                        <IonLabel>Ongelezen</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="read">
                        <IonLabel>Gelezen</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                <IonList className="ion-no-padding">
                    {messages.unread.length === 0 && selectedSegment === 'unread' && (
                        <IonItem>
                            <h4>Geen ongelezen berichten gevonden</h4>
                        </IonItem>
                    )}
                    {messages.read.length === 0 && selectedSegment === 'read' && (
                        <IonItem>
                            <h4>Geen gelezen berichten gevonden</h4>
                        </IonItem>
                    )}
                    {selectedSegment === 'unread' && (
                        messages.unread.map((message) => (
                            <IonItem key={message.id}>
                                <IonLabel
                                    className="ion-text-wrap">{message.message}<br/><IonNote>{formatDate(message.created_on)}</IonNote></IonLabel>
                                <IonButton slot="end" fill="clear" color="medium" onClick={() => markRead(message.id)}>
                                    <IonIcon icon={eye} slot="icon-only"/>
                                </IonButton>
                            </IonItem>
                        ))
                    )}
                    {selectedSegment === 'read' && (
                        messages.read.map((message) => (
                            <IonItem key={message.id}>
                                <IonLabel
                                    className="ion-text-wrap">{message.message}<br/><IonNote>{formatDate(message.created_on)}</IonNote></IonLabel>
                            </IonItem>
                        ))
                    )}
                </IonList>
            </IonContent>
            {loading && <IonLoading isOpen/>}
        </IonPage>
    );
};

export default Messages;
