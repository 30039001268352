import {
  IonButton,
  IonButtons, IonContent,
  IonHeader, IonIcon,
  IonItem, IonLabel, IonList, IonPage, IonToolbar
} from '@ionic/react';
import React from "react";
import {closeOutline, informationCircleOutline, pinOutline} from "ionicons/icons";

const KeyList: React.FC<{onDismiss: () => void; list: any[]}> = ({onDismiss, list}) => (
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonButton onClick={onDismiss}>
                        <IonIcon slot="icon-only" icon={closeOutline}/>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
      <IonContent fullscreen>
        <IonList className="ion-no-padding">
          {list.map((item, index) => (
            <div key={index}>
              <IonItem color="light" lines="full">
                <h3>{item.customer}</h3>
              </IonItem>
              <IonItem lines="none">
                <IonIcon icon={pinOutline} slot="start"/>
                <IonLabel>
                  <p>{item.street}</p>
                  <p>{item.zipcode} {item.city}</p>
                </IonLabel>
              </IonItem>
              <IonItem lines="full">
                <IonIcon icon={informationCircleOutline} slot="start"/>
                <IonLabel className="ion-text-wrap">
                  <p><b dangerouslySetInnerHTML={{ __html: item.info }}></b></p>
                </IonLabel>
              </IonItem>
            </div>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
);

export default KeyList;
