import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonList,
    IonItem,
    IonLoading,
    IonTitle,
    IonLabel,
    IonBadge,
    IonRefresher,
    IonRefresherContent,
    IonButton,
    useIonAlert, IonSegment, IonSegmentButton, IonNote, IonIcon, IonCard, useIonViewWillEnter, useIonToast
} from '@ionic/react';
import './PrioRoute.scss';
import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {useFetch} from "../../hooks/use-fetch";
import {arrowBackOutline, pinOutline, pulseOutline} from "ionicons/icons";
import {useSelector} from "react-redux";
import {AuthState} from "../../services/store";
import {Network} from "@capacitor/network";
import {Storage} from '@capacitor/storage';

const PrioRoute: React.FC = () => {
    const {token, role, id: user_id} = useSelector((state: AuthState) => state.auth.user);
    const {collectRouteData, loading, fetchData} = useFetch();
    const {id, route}: any = useParams();
    const [currentRoute, setCurrentRoute] = useState({delivery_data: [], clip_list: [], status: null, locked: null, owner: {id: '', name: ''}});
    const [selectedSegment, setSelectedSegment] = useState('delivery');
    const [locked, setLocked] = useState(true);
    const history = useHistory();
    const [present] = useIonAlert();
    const [toast] = useIonToast();
    const [teamLeader, setTeamLeader] = useState(false);

    const getData = useCallback(async (event?) => {
        if (token) {
            const tmpModified = await Storage.get({key: 'last_modified_route-'+route});

            const last_modified = +tmpModified.value;

            const res = await collectRouteData(JSON.stringify({last_modified, token, route_number: route}));

            if (res) {
                if (user_id === res.owner.id || !res.owner.id) {
                    setLocked(false);
                } else {
                    setLocked(true);
                }

                setCurrentRoute(res);
            } else {
                history.replace('/');
            }
        }

        if (event) {
            event.detail.complete();
        }
    }, [history, collectRouteData, token, route, user_id]);

    const prevPage = () => {
        history.replace(`/prio/${id}`);
    };

    useIonViewWillEnter(() => {
        getData().then();
    });

    useEffect(() => {
        if (role === '*TLLAADP') {
            setTeamLeader(true);
        }
        getData().then();
    }, [getData, token, role]);

    const presentClaimRoute = async () => {
        const net = await Network.getStatus();
        if (net.connected) {
            present({
                header: 'Weet je zeker dat je de route wilt claimen?',
                buttons: [
                    {
                        text: 'Nee',
                        role: 'cancel'
                    },
                    {
                        text: 'Ja',
                        handler: async () => {
                            const res = await fetchData('ZidaClaimRoute', 'POST', JSON.stringify({token, route}));
                            if (res.status === 200) {
                                setLocked(false);
                                setCurrentRoute((prevState) => {
                                    return {
                                        ...prevState,
                                        status: 502
                                    }
                                });
                                presentToast('Route is succesvol geclaimd', 'success');
                            }
                        }
                    }
                ]
            });
        } else {
            await offlineToast();
        }
    };

    const presentReleaseRoute = async () => {
        const net = await Network.getStatus();
        if (net.connected) {
            present({
                header: 'Weet je zeker dat je de route wilt vrijgeven?',
                buttons: [
                    {
                        text: 'Nee',
                        role: 'cancel'
                    },
                    {
                        text: 'Ja',
                        handler: async () => {
                            const res = await fetchData('ZidaReleaseRoute', 'POST', JSON.stringify({token, route}));
                            if (res.status === 200) {
                                setLocked(true);
                                setCurrentRoute((prevState) => {
                                    return {
                                        ...prevState,
                                        status: 501
                                    }
                                });
                                presentToast('Route is succesvol vrijgegeven', 'success');
                            }
                        }
                    }
                ]
            });
        } else {
            await offlineToast();
        }
    };

    const presentConfirmLoading = async () => {
        const net = await Network.getStatus();
        if (net.connected) {
            present({
                header: 'Weet je zeker dat de volledige route geladen is?',
                buttons: [
                    {
                        text: 'Nee',
                        role: 'cancel'
                    },
                    {
                        text: 'Ja',
                        handler: async () => {
                            const res = await fetchData('ZidaConfirmLoadingRoute', 'POST', JSON.stringify({token, route}));
                            if (res.status === 200) {
                                setLocked(true);
                                setCurrentRoute((prevState) => {
                                    return {
                                        ...prevState,
                                        status: 505
                                    }
                                });
                                presentToast('Route is succesvol geladen', 'success');
                            }
                        }
                    }
                ]
            });
        } else {
            await offlineToast();
        }
    };

    const presentReopenRoute = async () => {
        const net = await Network.getStatus();
        if (net.connected) {
            present({
                header: 'Weet je zeker dat je de route wilt heropenen?',
                buttons: [
                    {
                        text: 'Nee',
                        role: 'cancel'
                    },
                    {
                        text: 'Ja',
                        handler: async () => {
                            const res = await fetchData('ZidaReopenRoute', 'POST', JSON.stringify({token, route}));
                            if (res.status === 200) {
                                setLocked(false);
                                setCurrentRoute((prevState) => {
                                    return {
                                        ...prevState,
                                        status: 504
                                    }
                                });
                                presentToast('Route is succesvol heropend', 'success');
                            }
                        }
                    }
                ]
            });
        } else {
            await offlineToast();
        }
    };

    const presentToast = (message, color?) => {
        toast({
            message,
            color: color ? color: '',
            duration: 2000
        });
    };

    const offlineToast = () => {
        toast({
            message: 'Je bent offline, probeer het later opnieuw',
            color: 'warning',
            duration: 2000
        });
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={prevPage}><IonIcon icon={arrowBackOutline} slot="icon-only"/></IonButton>
                    </IonButtons>
                    <IonTitle>Route {route}</IonTitle>
                    {(route !== '00' && route !== '99') && (
                        <IonButtons slot="end">
                            {currentRoute.status === 501 &&
                                <IonButton onClick={presentClaimRoute}>Claim Route</IonButton>
                            }
                            {currentRoute.status === 502 && !locked &&
                                <IonButton onClick={presentReleaseRoute}>Route vrijgeven</IonButton>
                            }
                            {currentRoute.status === 504 && !locked &&
                                <IonButton onClick={presentConfirmLoading}>Geladen</IonButton>
                            }
                            {currentRoute.status === 505 && teamLeader &&
                                <IonButton onClick={presentReopenRoute}>Route heropenen</IonButton>
                            }
                        </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonRefresher onIonRefresh={getData} slot="fixed">
                    <IonRefresherContent/>
                </IonRefresher>
                <IonSegment value={selectedSegment} onIonChange={(event) => setSelectedSegment(event.detail.value)}>
                    <IonSegmentButton value="delivery">
                        <IonLabel>Route data</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton disabled={currentRoute.clip_list && currentRoute.clip_list.length === 0} value="clip">
                        <IonLabel>Klangenlijst</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                <IonList className="ion-no-padding">
                    {selectedSegment === 'delivery' && currentRoute.delivery_data && currentRoute.delivery_data.length === 0 && (
                        <IonItem lines="none">
                            <h3>Geen route data gevonden</h3>
                        </IonItem>
                    )}
                    {selectedSegment === 'delivery' && currentRoute.delivery_data && (
                        currentRoute.delivery_data.map((item) => (
                            <IonCard button routerLink={`/prio/${id}/${route}/${item.unique_key}`} key={item.unique_key}
                                     disabled={([501, 505].indexOf(currentRoute.status) > -1) && (route !== '00' && route !== '99')}>
                                <IonItem lines="none">
                                    <IonBadge slot="start"
                                              className="color-indicator color-indicator--505">{item.volgorde}</IonBadge>
                                    <h3>{item.name}</h3>
                                </IonItem>
                                <IonItem lines="none">
                                    <IonIcon slot="start" icon={pinOutline}/>
                                    <IonLabel className="ion-text-wrap">
                                        <p>{item.address}</p>
                                        <p>{item.postal_code} {item.city}</p>
                                    </IonLabel>
                                    {item.orders.length > 1 && <IonBadge>{item.orders.length}</IonBadge>}
                                    <IonBadge slot="end" className={`color-indicator color-indicator--${item.status}`}>
                                        <IonIcon icon={pulseOutline}/>
                                    </IonBadge>
                                </IonItem>
                            </IonCard>
                        ))
                    )}
                    {selectedSegment === 'clip' && currentRoute.clip_list && currentRoute.clip_list.length === 0 && (
                        <IonItem><h3>Geen klangenlijst gevonden</h3></IonItem>
                    )}
                    {selectedSegment === 'clip' && currentRoute.clip_list && (
                        currentRoute.clip_list.map((item, index) => (
                            <div key={index}>
                                <IonItem color="dark" lines="full">
                                    <h3>{item.customer}</h3>
                                </IonItem>
                                {item.orders.map((order) => (
                                    <div key={order.order_id}>
                                        <IonItem lines="full">
                                            <p><b>{order.order_id}</b></p>
                                        </IonItem>
                                        {order.articles.map((article, j) => (
                                            <IonItem key={article.row_key} color={j % 2 === 0 ? 'light' : ''}>
                                                <IonBadge className="ion-margin-vertical ion-align-self-start"
                                                          style={{minWidth: '45px'}}
                                                          slot="start">{article.amount}<br/>{article.unit_label}
                                                </IonBadge>
                                                <IonLabel className="ion-text-wrap">
                                                    <p style={{color: '#000'}}><b>{article.articlenumber}</b></p>
                                                    <p style={{color: '#000'}}>{article.description}</p>
                                                    {article.remark &&
                                                    <IonNote color="danger">{article.remark}</IonNote>}
                                                </IonLabel>
                                            </IonItem>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))
                    )}
                </IonList>
            </IonContent>
            {loading && <IonLoading isOpen/>}
        </IonPage>
    );
};

export default PrioRoute;
