import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonList,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonLoading,
    IonButton,
    IonTitle,
    useIonViewWillEnter,
    IonItem,
    useIonAlert,
    IonBadge,
    IonCard,
    IonLabel,
    useIonModal,
    IonSegment, IonSegmentButton
} from '@ionic/react';
import './DeliveryToday.scss';
import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router";
import {
    alarmOutline,
    arrowBackOutline,
    callOutline,
    chevronForwardOutline, constructOutline,
    pinOutline, refreshCircleOutline,
    repeatOutline
} from "ionicons/icons";
import {useFetch} from "../../hooks/use-fetch";
import {useSelector} from "react-redux";
import {AuthState} from "../../services/store";
import {Storage} from "@capacitor/storage";
import KeyList from "../../modals/KeyList";

const DeliveryToday: React.FC = () => {
    const {token} = useSelector((state: AuthState) => state.auth.user);
    const {loading, getRouteData} = useFetch();
    const history = useHistory();
    const [routeList, setRouteList] = useState([]);
    const [selectedSegment, setSelectedSegment] = useState('to_deliver');
    const [currentRoute, setCurrentRoute] = useState({
        route: null,
        key_info: [],
        delivery_data: {to_deliver: [], delivered: []}
    });
    const [present, dismiss] = useIonAlert();

    const handleDismiss = () => {
        dismissKeyModal();
    }

    const [keyModal, dismissKeyModal] = useIonModal(KeyList, {
        onDismiss: handleDismiss,
        list: currentRoute.key_info
    });

    const chooseRoute = useCallback((routes: any[]) => {
        present({
            header: 'Selecteer route',
            buttons: routes
        });
    }, [present]);

    const getData = useCallback(async (event?) => {
        const tmpRouteList = [];
        if (token) {
            const data = await getRouteData(JSON.stringify({token}));

            if (data.current_work_day) {
                for (const key in data.current_work_day) {
                    tmpRouteList.push({
                        text: 'Route ' + key, handler: async () => {
                            setCurrentRoute(data.current_work_day[key]);
                            await Storage.set({key: 'current_route', value: key});
                        }
                    });
                }
                setRouteList(tmpRouteList);

                const tmpCur = await Storage.get({key: 'current_route'});

                if (tmpCur.value && data.current_work_day[tmpCur.value]) {
                    setCurrentRoute(data.current_work_day[tmpCur.value])
                } else if (tmpRouteList.length > 1) {
                    await dismiss();
                    chooseRoute(tmpRouteList);
                } else if (tmpRouteList.length === 1) {
                    Object.keys(data.current_work_day).map(async (item) => {
                        setCurrentRoute(data.current_work_day[item])
                        await Storage.set({key: 'current_route', value: item});
                    })
                } else {
                    present('Geen routes gevonden');
                }
            } else {
                present('Geen routes gevonden');
            }
        }

        if (event) {
            event.detail.complete();
        }
    }, [dismiss, chooseRoute, getRouteData, token, present]);

    const prevPage = () => {
        history.replace(`/`);
    };

    let getTimeout;

    useIonViewWillEnter(() => {
        clearTimeout(getTimeout);
        getTimeout = setTimeout(() => {
            getData().then();
        }, 200);
    });

    useEffect(() => {
        clearTimeout(getTimeout);
        getTimeout = setTimeout(() => {
            getData().then();
        }, 200);
    }, [getData]);

    const presentKeyInfo = async () => {
        keyModal();
    };

    const callNumber = async (telNumber: string) => {
        window.open('tel:' + telNumber);
    };

    const checkTime = (endTime) => {
        // const currentDate = new Date();
        //
        // let endDate = new Date(currentDate.getTime());
        // endDate.setHours(endTime.split(":")[0]);
        // endDate.setMinutes(endTime.split(":")[1]);
        // endDate.setSeconds(endTime.split(":")[2]);
        //
        // const diff = endDate.getTime() - currentDate.getTime();
        //
        // if (diff/60000 <= 0) {
        //     return 'secondary';
        // } else if (diff/60000 <= 30) {
        //     return 'warning';
        // }

        return '';
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={prevPage}><IonIcon icon={arrowBackOutline} slot="icon-only"/></IonButton>
                    </IonButtons>
                    <IonTitle>{currentRoute.route && `Route ${currentRoute.route}`}</IonTitle>
                    {routeList.length > 1 && (
                        <IonButtons slot="end">
                            <IonButton onClick={() => chooseRoute(routeList)}>Kies route</IonButton>
                        </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonSegment value={selectedSegment}
                            onIonChange={(event) => setSelectedSegment(event.detail.value)}>
                    <IonSegmentButton value="to_deliver">
                        Te leveren
                    </IonSegmentButton>
                    <IonSegmentButton value="delivered">
                        Geleverd
                    </IonSegmentButton>
                </IonSegment>
                <IonRefresher onIonRefresh={getData} slot="fixed">
                    <IonRefresherContent/>
                </IonRefresher>
                <IonList className="ion-no-padding">
                    {currentRoute.key_info.length > 0 && (
                        <IonItem lines="full" button onClick={presentKeyInfo} color="light">
                            <IonBadge slot="start" color="primary" className="color-indicator color-indicator--504"/>
                            <h3>Sleuteladressen</h3>
                            <IonIcon slot="end" icon={chevronForwardOutline}/>
                        </IonItem>
                    )}
                    {selectedSegment === 'to_deliver' && currentRoute.delivery_data.to_deliver.length === 0 && (
                        <IonItem>
                            <h3>Geen route data gevonden</h3>
                        </IonItem>
                    )}
                    {selectedSegment === 'to_deliver' && currentRoute.delivery_data.to_deliver.map((customer, index) => (
                        <IonCard button routerLink={`/tabs/delivery_today/${customer.unique_key}`} key={customer.unique_key}>
                            <IonItem lines="none">
                                <IonBadge slot="start" className="color-indicator color-indicator--502">{index + 1}</IonBadge>
                                <h3>{customer.name}</h3>
                                {customer.orders[0].shipping.contact_phone && (
                                  <IonButtons slot="end">
                                      <IonButton onClick={() => callNumber(customer.orders[0].shipping.contact_phone)}><IonIcon icon={callOutline}/></IonButton>
                                  </IonButtons>
                                )}
                            </IonItem>
                            {customer.delivery_timeframe && customer.delivery_timeframe.timeframe_title && (
                              <IonItem lines="none" color={checkTime(customer.delivery_timeframe.hour_to)}>
                                  <IonLabel color={"dark"} className={"ion-text-wrap"}>
                                      <p>{customer.delivery_timeframe.timeframe_title}</p>
                                  </IonLabel>
                              </IonItem>
                            )}
                            <IonItem lines="none">
                                <IonIcon slot="start" icon={pinOutline}/>
                                <IonLabel>
                                    <p>{customer.address}</p>
                                    <p>{customer.postal_code} {customer.city}</p>
                                </IonLabel>
                                {customer.orders.length > 1 && <IonBadge>{customer.orders.length}</IonBadge>}
                            </IonItem>
                            {(customer.has_early === 1 || customer.has_refold === 1 || customer.has_return === 1 || customer.is_construction_site === 1 || customer.volume_data_html) && (
                              <IonItem lines="none">
                                {customer.has_early === 1 && <IonIcon icon={alarmOutline} color="danger" style={{marginRight: '10px'}}/>}
                                {customer.has_refold === 1 && <IonIcon icon={repeatOutline} color="danger" style={{marginRight: '10px'}}/>}
                                {customer.has_return === 1 && <IonIcon icon={refreshCircleOutline} color="danger" style={{marginRight: '10px'}}/>}
                                {customer.is_construction_site === 1 && <IonIcon icon={constructOutline} color="danger" style={{marginRight: '10px'}}/>}
                                {customer.volume_data_html && <span dangerouslySetInnerHTML={{__html: customer.volume_data_html}}></span>}
                              </IonItem>
                            )}
                        </IonCard>
                    ))}
                    {selectedSegment === 'delivered' && currentRoute.delivery_data.delivered.length === 0 && (
                        <IonItem>
                            <h3>Geen route data gevonden</h3>
                        </IonItem>
                    )}
                    {selectedSegment === 'delivered' && currentRoute.delivery_data.delivered.map((customer, index) => (
                        <IonCard button routerLink={`/tabs/delivery_today/${customer.unique_key}`} key={customer.unique_key}>
                            <IonItem lines="none">
                                <IonBadge slot="start" className="color-indicator color-indicator--502">{index + 1}</IonBadge>
                                <h3>{customer.name}</h3>
                                <IonButtons slot="end">
                                    <IonButton onClick={() => callNumber(customer.orders[0].shipping.contact_phone)}><IonIcon icon={callOutline}/></IonButton>
                                </IonButtons>
                            </IonItem>
                            <IonItem lines="none">
                                <IonIcon slot="start" icon={pinOutline}/>
                                <IonLabel>
                                    <p>{customer.address}</p>
                                    <p>{customer.postal_code} {customer.city}</p>
                                </IonLabel>
                                {customer.orders.length > 1 && <IonBadge>{customer.orders.length}</IonBadge>}
                            </IonItem>
                            {(customer.has_early === 1 || customer.has_refold === 1 || customer.has_return === 1 || customer.is_construction_site === 1 || customer.volume_data_html) && (
                                <IonItem lines="none">
                                    {customer.has_early === 1 && <IonIcon icon={alarmOutline} color="danger" style={{marginRight: '10px'}}/>}
                                    {customer.has_refold === 1 && <IonIcon icon={repeatOutline} color="danger" style={{marginRight: '10px'}}/>}
                                    {customer.has_return === 1 && <IonIcon icon={refreshCircleOutline} color="danger" style={{marginRight: '10px'}}/>}
                                    {customer.is_construction_site === 1 && <IonIcon icon={constructOutline} color="danger" style={{marginRight: '10px'}}/>}
                                    {customer.volume_data_html && <span dangerouslySetInnerHTML={{__html: customer.volume_data_html}}></span>}
                                </IonItem>
                            )}
                        </IonCard>
                    ))}
                </IonList>
            </IonContent>
            {loading && <IonLoading isOpen/>}
        </IonPage>
    );
};

export default DeliveryToday;
