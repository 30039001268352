import {
    IonContent, IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu, IonNote,
    IonTitle,
    IonToolbar,
} from '@ionic/react';

import './Menu.scss';

import {menuController} from '@ionic/core';
import {logOutOutline, notificationsOutline} from 'ionicons/icons';
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../services/auth";
import {useFetch} from "../hooks/use-fetch";
import {AuthState} from "../services/store";
import {App} from "@capacitor/app";
import {useEffect, useState} from "react";
import {Device} from "@capacitor/device";

const Menu: React.FC = () => {
    const {token} = useSelector((state: AuthState) => state.auth.user);
    const dispatch = useDispatch();
    const {fetchData} = useFetch();
    const [version, setVersion] = useState('');

    useEffect(() => {
        Device.getInfo().then((device) => {
            if (device.platform === 'android') {
                App.getInfo().then((res) => {
                    if (res) {
                        setVersion(res.version);
                    }
                });
            } else {
                setVersion(device.platform);
            }
        });

    }, []);

    return (
        <IonMenu contentId="main" type="overlay">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Menu</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem button routerLink="/messages" onClick={() => menuController.toggle()}>
                        <IonIcon slot="start" icon={notificationsOutline}/>
                        <IonLabel>Berichten</IonLabel>
                    </IonItem>
                    <IonItem button onClick={async () => {
                        await fetchData('ZidaRemoveRegistrationId', 'POST', JSON.stringify({token}));
                        dispatch(authActions.logout());
                        await menuController.toggle();
                    }}>
                        <IonIcon slot="start" icon={logOutOutline}/>
                        <IonLabel>Log-out</IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
            <IonFooter>
                <IonItem>
                    <IonLabel>
                        <IonNote>
                            App versie: {version}
                        </IonNote>
                    </IonLabel>
                </IonItem>
            </IonFooter>
        </IonMenu>
    );
};

export default Menu;
