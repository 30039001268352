import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonList,
    IonItem,
    IonBadge,
    IonLabel,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonLoading,
    IonNote,
    IonSelect,
    IonSelectOption,
    IonSegment,
    IonSegmentButton,
    IonButton,
    IonSlides,
    IonSlide,
    IonCard, useIonAlert, useIonViewWillEnter, useIonToast
} from '@ionic/react';
import './PrioRouteDetail.scss';
import React, {useCallback, useEffect, useState} from "react";
import {Storage} from "@capacitor/storage";
import {useHistory, useParams} from "react-router";
import {
  alarmOutline, alertCircleOutline, arrowBackOutline, chatboxEllipsesOutline, chatboxOutline,
  closeOutline, createOutline, documentOutline, navigateCircleOutline,
  pinOutline,
  pulseOutline
} from "ionicons/icons";
import {useFetch} from "../../hooks/use-fetch";
import '@ionic/react/css/ionic-swiper.css';
import {useSelector} from "react-redux";
import {AuthState} from "../../services/store";
import {Network} from "@capacitor/network";

const PrioRouteDetail: React.FC = () => {
    const {token, role, id: user_id} = useSelector((state: AuthState) => state.auth.user);
    const {id, route, unique_key}: any = useParams();
    const [teamLeader, setTeamLeader] = useState(false);
    const [customer, setCustomer] = useState({
        name: '',
        address: '',
        postal_code: '',
        city: '',
        status: null,
        orders: []
    });
    const [routeList, setRouteList] = useState([]);
    const [locked, setLocked] = useState(true);
    const [selectedSegment, setSelectedSegment] = useState('order_list');
    const history = useHistory();
    const {collectRouteData, loading, fetchData} = useFetch();
    const [fileList, setFileList] = useState([]);
    const [localOrderEntries, setLocalOrderEntries] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [present, dismiss] = useIonAlert();
    const [toast] = useIonToast();

    const getData = useCallback(async (event?) => {
        if (token) {
            const tmpModified = await Storage.get({key: 'last_modified_route-'+route});

            const last_modified = +tmpModified.value;

            const res = await collectRouteData(JSON.stringify({last_modified, token, route_number: route}));
            const general = await Storage.get({key: 'general'});

            if (general.value) {
                const jsonData = JSON.parse(general.value);
                setRouteList(jsonData.routes);
            }

            const tmpOrderEntries = await Storage.get({key: 'order_entries'});

            if (tmpOrderEntries.value) {
                setLocalOrderEntries(await JSON.parse(tmpOrderEntries.value));
            }

            if (res) {
                setCustomer(res.delivery_data.find(customer => unique_key === customer.unique_key));
                if (user_id === res.owner.id) {
                    setLocked(false);
                } else {
                    setLocked(true);
                }

            } else {
                history.replace('/');
            }
        }

        if (event) {
            event.detail.complete();
        }
    }, [unique_key, history, collectRouteData, token, route, user_id]);

    const prevPage = () => {
        history.replace(`/prio/${id}/${route}`);
    };

    const changeRoute = async (order, route) => {
        const net = await Network.getStatus();
        if (net.connected) {
            await fetchData('ZidaChangeRouteOfDelivery', 'POST', JSON.stringify({
                token,
                order_id: order,
                new_route: route
            }));
            await getData();
        } else {
            await offlineToast();
        }
    };

    const presentRequestHelp = async (order, lock?) => {
        console.log(lock);
        const net = await Network.getStatus();
        if (net.connected) {
            present({
                header: `Weet je zeker dat je om hulp wilt vragen voor order: ${order}?`,
                buttons: [
                    {
                        text: 'Annuleren',
                        role: 'cancel'
                    },
                    {
                        text: 'Verzenden',
                        handler: async () => {
                            await fetchData('ZidaRequestHelpWithDelivery', 'POST', JSON.stringify({
                                token,
                                order_id: order
                            }));
                            await getData();
                            await presentToast(`Er is om hulp gevraagd voor order: ${order}`, 'success');
                        }
                    }
                ]
            });
        } else {
            await offlineToast();
        }
    }

    const editRemarks = async (order) => {
        const net = await Network.getStatus();
        if (net.connected) {
            const currentOrder = await customer.orders.find(x => x.order_id === order);
            await present({
                header: 'Voer uw opmerking(en) in',
                inputs: [
                    {
                        name: 'driver',
                        type: 'textarea',
                        placeholder: 'Opmerking voor chauffeur',
                        value: currentOrder.loading.remarks ? atob(currentOrder.loading.remarks.driver) : ''
                    },
                    {
                        name: 'loading',
                        type: 'textarea',
                        placeholder: 'Opmerking voor laadploeg',
                        value: currentOrder.loading.remarks ? atob(currentOrder.loading.remarks.loading) : ''
                    }
                ],
                buttons: [
                    {
                        text: 'Annuleren',
                        role: 'cancel'
                    },
                    {
                        text: 'Verzenden',
                        handler: (data) => {
                            saveRemarks(order, btoa(data.driver), btoa(data.loading));
                        }
                    }
                ]
            });
        } else {
            await offlineToast();
        }
    };

    const saveRemarks = async (order, driver, loading) => {
        await fetchData('ZidaSaveLoadingRemarks', 'POST', JSON.stringify({
            token,
            order_id: order,
            remarks: {driver, loading}
        }));
        await getData();
    };

    const openFile = async (key) => {
        const net = await Network.getStatus();
        if (net.connected) {
            const content = await fetchData('ZidaGetPdfAttachment', 'POST', JSON.stringify({token, download_key: key}));
            const res = await content.data;
            if (res) {
                setFileList(res);
            } else {
                present(content.message);
            }
        } else {
            await offlineToast();
        }
    };

    const getIndex = (event) => {
        event.target.getActiveIndex().then((value) => {
            setCurrentPage(value + 1);
        });
    };

    const closeFile = async () => {
        setFileList([]);
        setCurrentPage(1);
    };

    const presentCheckLoading = async () => {
        const net = await Network.getStatus();
        if (net.connected) {
            present({
                header: 'Weet je zeker dat je de orders wilt opslaan?',
                buttons: [
                    {
                        text: 'Nee',
                        role: 'cancel'
                    },
                    {
                        text: 'Ja',
                        handler: async () => {
                            if (route > 50) {
                                await checkLoading50();
                            } else {
                                await checkLoading();
                            }
                        }
                    }
                ]
            })
        } else {
            await offlineToast();
        }
    };

    const checkLoading = async () => {
        dismiss();
        const orderEntries = await Storage.get({key: 'order_entries'});
        if (orderEntries.value) {
            const storageOrders = await JSON.parse(orderEntries.value);
            let count = customer.orders.length;
            customer.orders.map((order) => {
                const currentOrder = storageOrders.find((item) => item.order_id === order.order_id);
                if (currentOrder) {
                    count--;
                }
            });
            if (count === 0) {
                const tmpArray = storageOrders;
                const tmpOrders = [];
                customer.orders.map(async (order) => {
                    const currentOrder = storageOrders.find((item) => item.order_id === order.order_id);
                    if (currentOrder) {
                        tmpArray.splice(storageOrders.indexOf(currentOrder), 1);
                        tmpOrders.push({
                            order_id: currentOrder.order_id,
                            volume_data: currentOrder.itemsArray
                        });
                    }
                });
                const res = await fetchData('ZidaCheckLoadingByAddress', 'POST', JSON.stringify({
                    token,
                    data: tmpOrders,
                    route
                }));
                await Storage.set({key: 'order_entries', value: JSON.stringify(tmpArray)});
                if (res.status === 200) {
                    setCustomer(res.data.delivery_data.find(customer => unique_key === customer.unique_key));
                    setLocalOrderEntries([]);
                    if (user_id === res.data.owner.id) {
                        setLocked(false);
                    } else {
                        setLocked(true);
                    }
                    presentToast('Orders zijn succesvol opgeslagen', 'success');
                }
            } else {
                present({
                    header: 'Niet alle orders hebben volume data',
                    buttons: [
                        {
                            text: 'OK'
                        }
                    ]
                });
            }
        } else {
            present({
                header: 'Niet alle orders hebben volume data',
                buttons: [
                    {
                        text: 'OK'
                    }
                ]
            });
        }
    };

    const checkLoading50 = async () => {
        const orderEntries = await Storage.get({key: 'order_entries'});
        const storageOrders = await JSON.parse(orderEntries.value);
        let tmpArray = [];
        const tmpOrders = [];
        if (storageOrders) {
            tmpArray = storageOrders;
        }
        customer.orders.map(async (order) => {
            if (storageOrders) {
                const currentOrder = storageOrders.find((item) => item.order_id === order.order_id);
                if (currentOrder) {
                    tmpArray.splice(storageOrders.indexOf(currentOrder), 1);
                    tmpOrders.push({
                        order_id: currentOrder.order_id,
                        volume_data: currentOrder.itemsArray
                    });
                } else {
                    tmpOrders.push({
                        order_id: order.order_id,
                        volume_data: []
                    });
                }
            } else {
                tmpOrders.push({
                    order_id: order.order_id,
                    volume_data: []
                });
            }
        });
        const res = await fetchData('ZidaCheckLoadingByAddress', 'POST', JSON.stringify({
            token,
            data: tmpOrders,
            route
        }));
        await Storage.set({key: 'order_entries', value: JSON.stringify(tmpArray)});
        if (res.status === 200) {
            setCustomer(res.data.delivery_data.find(customer => unique_key === customer.unique_key));
            setLocalOrderEntries([]);
            if (user_id === res.data.owner.id) {
                setLocked(false);
            } else {
                setLocked(true);
            }
            presentToast('Orders zijn succesvol opgeslagen', 'success');
        }
    };

    const returnOrders = async () => {
        const orderEntries = await Storage.get({key: 'order_entries'});
        let orderEntriesList = [];
        const tmpOrders = [];
        if (orderEntries.value) {
            const tmpArray = await JSON.parse(orderEntries.value);
            orderEntriesList = await tmpArray;
        }
        customer.orders.map(async (order, index) => {
            const currentOrder = orderEntriesList.find(item => order.order_id === item.order_id);
            if (currentOrder) {
                orderEntriesList.slice(index, 1);
            }
            orderEntriesList.push({order_id: order.order_id, itemsArray: order.loading.volume_data});
            tmpOrders.push(order.order_id);
        });
        const res = await fetchData('ZidaReopenAddress', 'POST', JSON.stringify({token, data: tmpOrders, route}));
        await Storage.set({key: 'order_entries', value: JSON.stringify(orderEntriesList)});
        if (res.status === 200) {
            setCustomer(res.data.delivery_data.find(customer => unique_key === customer.unique_key));
            setLocalOrderEntries(orderEntriesList);
            if (user_id === res.data.owner.id) {
                setLocked(false);
            } else {
                setLocked(true);
            }
            presentToast('Orders zijn succesvol teruggezet', 'success');
        }
    };

    const presentReturnOrders = async () => {
        const net = await Network.getStatus();
        if (net.connected) {
            present({
                header: 'Weet je zeker dat je alle orders wilt terugzetten naar in behandeling?',
                buttons: [
                    {
                        text: 'Nee',
                        role: 'cancel'
                    },
                    {
                        text: 'Ja',
                        handler: async () => {
                            await returnOrders();
                        }
                    }
                ]
            })
        } else {
            await offlineToast();
        }
    };

    const presentToast = (message, color?) => {
        toast({
            message,
            color: color ? color: '',
            duration: 2000
        });
    };

    const offlineToast = () => {
        toast({
            message: 'Je bent offline, probeer het later opnieuw',
            color: 'warning',
            duration: 2000
        });
    };

    useIonViewWillEnter(() => {
        getData().then();
    });

    useEffect(() => {
        if (role === '*TLLAADP') {
            setTeamLeader(true);
        }
        getData().then();
    }, [getData, token, role]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        {fileList.length > 0 &&
                        <IonButton onClick={closeFile}><IonIcon slot="icon-only" icon={closeOutline}/></IonButton>}
                        {fileList.length < 1 &&
                        <IonButton onClick={prevPage}><IonIcon slot="icon-only" icon={arrowBackOutline}/></IonButton>}
                    </IonButtons>
                    {fileList.length < 1 && (
                        <IonButtons slot="end">
                            {customer.status === 504 && (
                                <IonButton onClick={presentReturnOrders}>Order{customer.orders.length > 1 && 's'} terughalen</IonButton>
                            )}
                            {customer.status === 502 && !locked && (
                                <IonButton onClick={presentCheckLoading}>Gecontroleerd</IonButton>
                            )}
                        </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {fileList.length < 1 && (
                    <>
                        <IonRefresher onIonRefresh={getData} slot="fixed">
                            <IonRefresherContent/>
                        </IonRefresher>
                        <IonItem lines="none">
                            <h3>{customer.name} {customer.orders[0] &&
                            <IonNote>{customer.orders[0].deb_nummer}</IonNote>}</h3>
                        </IonItem>
                        <IonItem lines="full">
                            <IonIcon slot="start" icon={pinOutline}/>
                            <IonLabel>
                                <p>{customer.address}</p>
                                <p>{customer.postal_code} {customer.city}</p>
                            </IonLabel>
                            <IonBadge slot="end" className={`color-indicator color-indicator--${customer.status}`}>
                                <IonIcon icon={pulseOutline}/>
                            </IonBadge>
                        </IonItem>
                        <IonSegment value={selectedSegment}
                                    onIonChange={(event) => setSelectedSegment(event.detail.value)}>
                            <IonSegmentButton value="order_list">
                                <IonLabel>Orderregels</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="order_files">
                                <IonLabel>Bijlagen</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                        <IonList className="ion-no-padding">
                            {selectedSegment === 'order_list' && customer.orders.length === 0 && (
                                <IonItem>
                                    <h3>Geen orders gevonden</h3>
                                </IonItem>
                            )}
                            {customer.orders.map((item) => (
                                <div key={item.order_id}>
                                    <IonItem color={item.status === '512' ? 'danger' : 'dark'} lines="full">
                                        <h3 style={{display: 'flex', alignItems: 'center'}}>
                                            {item.order_id}
                                            {item.vroeg === '1' &&
                                            <IonIcon style={{marginLeft: '5px'}} icon={alarmOutline} color={item.status === '512' ? 'dark' : 'danger'}/>}
                                        </h3>
                                        <IonButtons slot="end">
                                            {item.status === '502' && !locked && (
                                                <IonButton onClick={() => presentRequestHelp(item.order_id)}>
                                                    <IonIcon color="secondary" icon={alertCircleOutline}/>
                                                </IonButton>
                                            )}
                                            <IonButton onClick={() => editRemarks(item.order_id)}>
                                                <IonIcon icon={chatboxEllipsesOutline}/>
                                            </IonButton>
                                            <IonButton
                                                routerLink={`/prio/${id}/${route}/${unique_key}/${item.order_id}`}
                                                disabled={item.status !== '502' || locked}>
                                                <IonIcon icon={createOutline}/>
                                            </IonButton>
                                        </IonButtons>
                                    </IonItem>
                                    {((localOrderEntries.length > 0 && localOrderEntries.some(tmpOrder => tmpOrder.order_id === item.order_id)) || item.loading.volume_data.length > 0 || (item.remarks && item.remarks.general !== '') || (item.remarks && item.remarks.transport !== '') || (item.loading.remarks && item.loading.remarks.loading !== '') || (item.loading.remarks && item.loading.remarks.driver !== '') || (item.routenummer !== item.routenummer_orig) || (item.order_sold)) && (
                                        <IonItem>
                                            <IonLabel className="ion-text-wrap">
                                                {item.routenummer !== item.routenummer_orig && <p><IonIcon style={{marginRight: '5px'}} icon={navigateCircleOutline}/><b>Orig. route: </b>{item.routenummer_orig}</p>}
                                                {item.order_sold && <p><b style={{color: 'var(--ion-color-secondary)'}}>VERKOCHT</b></p>}
                                                {(item.remarks && item.remarks.general !== '') &&
                                                  <p><IonIcon style={{marginRight: '5px'}}
                                                              icon={chatboxOutline}/><b>Algemeen:</b> {item.remarks.general}
                                                  </p>}
                                                {(item.remarks && item.remarks.transport !== '') &&
                                                  <p><IonIcon style={{marginRight: '5px'}}
                                                              icon={chatboxOutline}/><b>Transport:</b> {item.remarks.transport}
                                                  </p>}
                                                {(item.loading.remarks && item.loading.remarks.driver !== '') &&
                                                <p><IonIcon style={{marginRight: '5px'}}
                                                            icon={chatboxOutline}/><b>Chauffeur:</b> {atob(item.loading.remarks.driver)}
                                                </p>}
                                                {(item.loading.remarks && item.loading.remarks.loading !== '') &&
                                                <p><IonIcon style={{marginRight: '5px'}}
                                                            icon={chatboxOutline}/><b>Laadploeg:</b> {atob(item.loading.remarks.loading)}
                                                </p>}
                                                {localOrderEntries.length > 0 && (
                                                  localOrderEntries.map((tmpOrder) => (
                                                    tmpOrder.order_id === item.order_id && (
                                                      <p key={tmpOrder.order_id + 'volume'}>
                                                        {tmpOrder.itemsArray.map((item) => (
                                                          item.value > 0 ? (
                                                            <span key={tmpOrder.order_id + item.abbreviation} style={{marginRight: '10px'}}>
                                                                <b style={{marginRight: '5px'}}>
                                                                    {item.abbreviation}
                                                                </b>
                                                                {item.value}
                                                            </span>
                                                          ) : (item.value.value && (item.value.value > 0 || item.value.value !== '')) && (
                                                            <span key={tmpOrder.order_id + item.abbreviation} style={{marginRight: '10px'}}>
                                                                <b style={{marginRight: '5px'}}>
                                                                    {item.abbreviation.substr(0, 1)}
                                                                </b>
                                                                {item.value.value}
                                                                {item.value.direction === 'up' && '↑'}
                                                                {item.value.direction === 'down' && '↓'}
                                                                {item.value.direction === 'right' && '→'}
                                                                {item.value.direction === 'left' && '←'}
                                                            </span>
                                                          )
                                                        ))}
                                                      </p>
                                                    )
                                                  ))
                                                )}
                                              {!localOrderEntries.some((tmpOrder) => tmpOrder.order_id === item.order_id) && item.loading.volume_data.length > 0 &&
                                                  <p dangerouslySetInnerHTML={{__html: item.loading.volume_data_html}}/>}
                                            </IonLabel>
                                        </IonItem>
                                    )}
                                    {teamLeader && (
                                        <IonItem lines="inset">
                                            <IonLabel>
                                                <p>Verplaats {item.order_id} naar andere route</p>
                                            </IonLabel>
                                            <IonSelect value={item.routenummer} okText="Verplaats"
                                                       cancelText="Annuleren"
                                                       onIonChange={(event) => changeRoute(item.order_id, event.detail.value)}>
                                                {routeList.map((route) => (
                                                    <IonSelectOption key={route} value={route}>{route}</IonSelectOption>
                                                ))}
                                            </IonSelect>
                                        </IonItem>
                                    )}
                                    {selectedSegment === 'order_list' && (
                                        item.products.map((product, index) => (
                                            <IonItem key={product.row_key} color={index % 2 === 0 ? 'light' : ''}>
                                                <IonBadge className="ion-margin-vertical ion-align-self-start"
                                                          style={{minWidth: '45px'}}
                                                          slot="start">{product.amount}<br/>{product.unit_label}
                                                </IonBadge>
                                                <IonLabel className="ion-text-wrap">
                                                    <p style={{color: '#000'}}><b>{product.articlenumber}</b></p>
                                                    <p style={{color: '#000'}}>{product.description}</p>
                                                    {product.remark &&
                                                    <IonNote color="danger">{product.remark}</IonNote>}
                                                    {product.amount_text &&
                                                    <IonBadge color="medium">{product.amount_text}</IonBadge>}
                                                </IonLabel>
                                            </IonItem>
                                        ))
                                    )}
                                    {selectedSegment === 'order_files' && (
                                        item.attachments.map((attachment, index) => (
                                            <IonItem button onClick={() => openFile(attachment.download_key)}
                                                     key={attachment.download_key}
                                                     color={index % 2 === 0 ? 'light' : ''}>
                                                <IonIcon slot="start" icon={documentOutline}/>
                                                <IonLabel>
                                                    <p>{attachment.filename}</p>
                                                </IonLabel>
                                            </IonItem>
                                        ))
                                    )}
                                </div>
                            ))}
                        </IonList>
                    </>
                )}
                {fileList.length > 0 && (
                    <IonCard>
                        <IonItem lines="full">
                            <IonLabel>
                                Pagina {currentPage} van {fileList.length}
                            </IonLabel>
                        </IonItem>
                        <IonSlides id='slider' onIonSlideDidChange={(event) => getIndex(event)}>
                            {fileList.map((file) => (
                                <IonSlide key={file}>
                                    <img src={'data:image/jpeg; base64,' + file} alt="Afbeelding"/>
                                </IonSlide>
                            ))}
                        </IonSlides>
                    </IonCard>
                )}
            </IonContent>
            {loading && <IonLoading isOpen/>}
        </IonPage>
    );
};

export default PrioRouteDetail;
