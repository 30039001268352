import {createSlice} from "@reduxjs/toolkit";
import {Storage} from "@capacitor/storage";

const initialAuthState = {
    user: {token: '', role: '', id: null},
    isAuthenticated: false,
    isTest: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
        },
        login(state) {
            state.isAuthenticated = true;
        },
        logout(state) {
            Storage.clear().then();
            state.user = {token: '', role: '', id: null};
            state.isAuthenticated = false;
            state.isTest = false;
        },
        setTest(state, action) {
            state.isTest = action.payload;
        }
    }
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
