import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonLoading,
    IonItem, IonLabel, IonList, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon
} from '@ionic/react';
import './PrioRoute.scss';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Storage} from "@capacitor/storage";
import {useHistory, useParams} from "react-router";
import {useFetch} from "../../hooks/use-fetch";
import {closeOutline} from "ionicons/icons";
import {useSelector} from "react-redux";
import {AuthState} from "../../services/store";

const OrderEntry: React.FC = () => {
    const {token} = useSelector((state: AuthState) => state.auth.user);
    const {collectRouteData, loading} = useFetch();
    const {id, route, unique_key, order_id}: any = useParams();
    const [currentOrder, setCurrentOrder] = useState({loading: {volume_data: []}});
    const [generalVolume, setGeneralVolume] = useState([]);
    const history = useHistory();
    const itemRef = useRef([]);
    const selectRef = useRef([]);
    const [tmpOrder, setTmpOrder] = useState({itemsArray: []})

    const getData = useCallback(async (event?) => {
        if (token) {
            const tmpModified = await Storage.get({key: 'last_modified_route-'+route});
            const last_modified = +tmpModified.value;
            const general = await Storage.get({key: 'general'});

            const jsonGeneral = await JSON.parse(general.value);

            const data = await collectRouteData(JSON.stringify({last_modified, token, route_number: route}));

            if (data) {
                const volume_inputs = await jsonGeneral.volume_inputs;
                setGeneralVolume(volume_inputs);
                itemRef.current = itemRef.current.slice(0, volume_inputs.length);
                selectRef.current = selectRef.current.slice(0, 5);
                const currentCustomer = await data.delivery_data.find(customer => unique_key === customer.unique_key);
                setCurrentOrder(currentCustomer.orders.find(order => order_id === order.order_id));
            } else {
                history.replace('/');
            }
        }

        if (event) {
            event.detail.complete();
        }
    }, [unique_key, history, collectRouteData, token, route, order_id]);

    const getOrderEntries = useCallback(() => {
        Storage.get({key: 'order_entries'}).then(async (res) => {
            if (res.value) {
                const tmpArray = await JSON.parse(res.value);
                const currentOrder = tmpArray.find((order) => order.order_id === order_id);
                if (currentOrder) {
                    setTmpOrder(currentOrder);
                }
            }
        });
    }, [order_id]);

    useEffect(() => {
        getData().then();
        getOrderEntries();
    }, [getOrderEntries, order_id, getData, token, id, route]);

    const closeEntry = async () => {
        const itemsArray = [];
        const selectArray = [];
        let orderSave = false;
        selectRef.current.map((select) => selectArray.push({abbreviation: select.name, value: select.value}));
        itemRef.current.map((item, index) => {
            if (item.name.substr(0, 2) === 'M_') {
                itemsArray.push({
                    abbreviation: item.name,
                    value: {value: item.value, direction: selectArray[index].value}
                })
            } else {
                itemsArray.push({abbreviation: item.name, value: item.value});
            }
        });

        itemsArray.map((item) => {
            if (item.abbreviation.substr(0, 2) === 'M_' && item.value.value !== '') {
                orderSave = true;
            } else if (item.abbreviation.substr(0, 2) !== 'M_' && item.value > 0) {
                orderSave = true;
            }
        });

        if (orderSave) {
            const orderEntries = await Storage.get({key: 'order_entries'});
            if (orderEntries.value) {
                const tmpArray = await JSON.parse(orderEntries.value);
                const currentOrder = tmpArray.find((order) => order.order_id === order_id);
                if (currentOrder) {
                    const index = tmpArray.indexOf(currentOrder);
                    tmpArray[index] = {order_id, itemsArray};
                    await Storage.set({key: 'order_entries', value: JSON.stringify(tmpArray)});
                } else {
                    await Storage.set({
                        key: 'order_entries',
                        value: JSON.stringify([...tmpArray, {order_id, itemsArray}])
                    });
                }
            } else {
                await Storage.set({key: 'order_entries', value: JSON.stringify([{order_id, itemsArray}])});
            }
        }
        setTmpOrder({itemsArray: []});

        history.replace(`/prio/${id}/${route}/${unique_key}`);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={closeEntry}>
                            <IonIcon slot="icon-only" icon={closeOutline}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonItem lines="full" color="dark">
                    <h3>Order: {order_id}</h3>
                </IonItem>
                <IonList className="ion-no-padding">
                    <IonItem lines="full">Boxnummers:</IonItem>
                    {currentOrder.loading.volume_data.length > 0 && tmpOrder.itemsArray.length === 0 && currentOrder.loading.volume_data.map((item, index) => (
                        item.abbreviation.substr(0, 2) === 'M_' ? (
                            <IonItem key={item.abbreviation}>
                                <IonLabel>
                                    <p>#</p>
                                </IonLabel>
                                <IonInput ref={el => itemRef.current[index] = el} value={item.value.value}
                                          name={item.abbreviation}/>
                                <IonSelect ref={el => selectRef.current[index] = el} value={item.value.direction}
                                           name={"D_" + item.abbreviation} slot="end">
                                    <IonSelectOption value="">Geen</IonSelectOption>
                                    <IonSelectOption value="up">Boven</IonSelectOption>
                                    <IonSelectOption value="down">Onder</IonSelectOption>
                                    <IonSelectOption value="right">Rechts</IonSelectOption>
                                    <IonSelectOption value="left">Links</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        ) : (
                            <IonItem key={item.abbreviation}>
                                <IonLabel position="stacked">
                                    <p>{generalVolume.find((generalItem) => generalItem.abbreviation === item.abbreviation) ? generalVolume.find((generalItem) => generalItem.abbreviation === item.abbreviation).label : ''}</p>
                                </IonLabel>
                                <IonInput ref={el => itemRef.current[index] = el} type="number" value={item.value}
                                          min="0" name={item.abbreviation}/>
                            </IonItem>
                        )
                    ))}
                    {tmpOrder.itemsArray.length > 0 && tmpOrder.itemsArray.map((item, index) => (
                        item.abbreviation.substr(0, 2) === 'M_' ? (
                            <IonItem key={item.abbreviation}>
                                <IonLabel>
                                    <p>#</p>
                                </IonLabel>
                                <IonInput ref={el => itemRef.current[index] = el} value={item.value.value}
                                          name={item.abbreviation}/>
                                <IonSelect ref={el => selectRef.current[index] = el} value={item.value.direction}
                                           name={"D_" + item.abbreviation} slot="end">
                                    <IonSelectOption value="">Geen</IonSelectOption>
                                    <IonSelectOption value="up">Boven</IonSelectOption>
                                    <IonSelectOption value="down">Onder</IonSelectOption>
                                    <IonSelectOption value="right">Rechts</IonSelectOption>
                                    <IonSelectOption value="left">Links</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        ) : (
                            <IonItem key={item.abbreviation}>
                                <IonLabel position="stacked">
                                    <p>{generalVolume.find((generalItem) => generalItem.abbreviation === item.abbreviation) ? generalVolume.find((generalItem) => generalItem.abbreviation === item.abbreviation).label : ''}</p>
                                </IonLabel>
                                <IonInput ref={el => itemRef.current[index] = el} type="number" value={item.value}
                                          min="0" name={item.abbreviation}/>
                            </IonItem>
                        )
                    ))}
                    {currentOrder.loading.volume_data.length === 0 && tmpOrder.itemsArray.length === 0 && generalVolume.map((item, index) => (
                        item.input_type === 'box' ? (
                            <IonItem key={item.abbreviation}>
                                <IonLabel>
                                    <p>#</p>
                                </IonLabel>
                                <IonInput ref={el => itemRef.current[index] = el} value="" name={item.abbreviation}/>
                                <IonSelect ref={el => selectRef.current[index] = el} value=""
                                           name={"D_" + item.abbreviation} slot="end">
                                    <IonSelectOption value="">Geen</IonSelectOption>
                                    <IonSelectOption value="up">Boven</IonSelectOption>
                                    <IonSelectOption value="down">Onder</IonSelectOption>
                                    <IonSelectOption value="right">Rechts</IonSelectOption>
                                    <IonSelectOption value="left">Links</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        ) : (
                            <IonItem key={item.abbreviation}>
                                <IonLabel position="stacked">
                                    <p>{item.label}</p>
                                </IonLabel>
                                <IonInput ref={el => itemRef.current[index] = el} type="number" value="0" min="0"
                                          name={item.abbreviation}/>
                            </IonItem>
                        )
                    ))}
                </IonList>
            </IonContent>
            {loading && <IonLoading isOpen/>}
        </IonPage>
    );
};

export default OrderEntry;
