import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonLoading,
    IonItem, IonList, IonButton, IonIcon, IonNote, IonInput, IonLabel, useIonToast, useIonAlert
} from '@ionic/react';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router";
import {useFetch} from "../../hooks/use-fetch";
import {closeOutline} from "ionicons/icons";
import {useSelector} from "react-redux";
import {AuthState} from "../../services/store";
import {Storage} from "@capacitor/storage";
import {Network} from "@capacitor/network";

const Assembly: React.FC = () => {
    const {token} = useSelector((state: AuthState) => state.auth.user);
    const {getRouteData, loading, fetchData} = useFetch();
    const {unique_key, order_id}: any = useParams();
    const history = useHistory();
    const itemRef = useRef([]);
    const [currentAssembly, setCurrentAssembly] = useState({deb_naam: '', deb_nummer: ''});
    const [assembly, setAssembly] = useState([]);
    const [toast] = useIonToast();
    const [present, dismiss] = useIonAlert();


    const getData = useCallback(async (event?) => {
        const currentRouteId = await Storage.get({key: 'current_route'});

        if (currentRouteId.value && history.location.pathname.startsWith('/tabs/delivery_today')) {
            if (token) {
                const data = await getRouteData(JSON.stringify({token}));

                if (data) {
                    const toDeliver = await data.current_work_day[currentRouteId.value].delivery_data.to_deliver.find(item => item.unique_key === unique_key);
                    if (toDeliver) {
                        const currentOrder = await toDeliver.orders.find(item => item.order_id, order_id);
                        setAssembly(data.general.assembly);
                        if (currentOrder) {
                            setCurrentAssembly(currentOrder);
                        } else {
                            history.replace('/');
                        }
                    } else {
                        history.replace('/');
                    }
                } else {
                    history.replace('/');
                }
            }
        }

        if (event) {
            event.detail.complete();
        }
    }, [unique_key, history, getRouteData, token, order_id]);

    useEffect(() => {
        getData().then();
    }, [order_id, getData, token]);

    const prevPage = () => {
        history.replace(`/tabs/delivery_today/${unique_key}`);
    }

    const sendAssembly = async () => {
        const assemblyList = [];
        let assemblySave = false;
        let dontSave = false;
        let selectedAssemblyList = '';
        itemRef.current.map((item) => {
            assemblyList.push({
                name: item.name,
                description: item.dataset.description ? item.dataset.description : item.name,
                value: item.dataset.negative === "1" ? (item.value * -1).toString() : item.value,
                articleNumber: item.dataset.article ? item.dataset.article : '',
                boxnumbersRequired: item.dataset.box ? item.dataset.box : '0',
                isNegative: item.dataset.negative,
            });
        });

        assemblyList.map((item) => {
            if (item.boxnumbersRequired === '1' && item.value != 0 && assemblyList.find((box) => box.name === 'boxnumbers').value === '') {
                dontSave = true;
            }
            if (item.name !== 'boxnumbers' && item.isNegative === "0" && item.value > 0) {
                selectedAssemblyList = `${selectedAssemblyList}<br>${item.description}: ${item.value}`;
                assemblySave = true;
            } else if (item.name !== 'boxnumbers' && item.isNegative === "1" && item.value < 0) {
                selectedAssemblyList = `${selectedAssemblyList}<br>${item.description}: ${item.value}`;
                assemblySave = true;
            } else if (item.name === 'boxnumbers' && item.value !== '') {
                selectedAssemblyList = `${selectedAssemblyList}<br>${item.description}: ${item.value}`;
                assemblySave = true;
            }
        });

        if (dontSave) {
            toast({
                message: 'Baknummers zijn niet ingevuld',
                color: 'warning',
                duration: 2000
            });
        } else {
            if (assemblySave) {
                present({
                    cssClass: 'assembly-alert',
                    header: 'Controleer assemblage',
                    message: selectedAssemblyList,
                    buttons: [
                        {
                            text: 'Annuleren',
                            role: 'cancel'
                        },
                        {
                            text: 'Accepteren',
                            handler: async () => {
                                const net = await Network.getStatus();
                                if (net.connected) {
                                    const data = await fetchData('ZidaConfirmAssembly', 'POST', JSON.stringify({token, order_id, assembly: assemblyList}));

                                    if (data.status) {
                                        toast({
                                            message: 'Assemblage data is verzonden',
                                            color: 'success',
                                            duration: 2000
                                        });
                                        prevPage();
                                    }
                                } else {
                                    toast({
                                        message: 'Je bent offline, probeer het later opnieuw',
                                        color: 'warning',
                                        duration: 2000
                                    });
                                }
                            }
                        }
                    ]
                });
            } else {
                toast({
                    message: 'Er is geen data ingevuld',
                    color: 'warning',
                    duration: 2000
                });
            }
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={prevPage}>
                            <IonIcon slot="icon-only" icon={closeOutline}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonItem lines="full" color="dark">
                    <h3>{currentAssembly.deb_naam}<IonNote> {currentAssembly.deb_nummer}</IonNote></h3>
                </IonItem>
                <IonList className="ion-no-padding">
                    {assembly.length > 0 && assembly.map((item, index) => (
                      <IonItem key={item.id}>
                          <IonLabel position="floating">{item.description}</IonLabel>
                          <IonInput ref={el => itemRef.current[index] = el} type={item.input_type} value={item.input_type === 'number' ? '0' : ''} min={item.input_type === 'number' ? '0' : ''} name={item.system_name} data-article={item.articlenumber} data-box={item.boxnumbers_required} data-negative={item.is_negative} data-description={item.description}/>
                      </IonItem>
                    ))}
                    <IonButton onClick={sendAssembly} color="light" expand="block">Verzenden</IonButton>
                </IonList>
            </IonContent>
            {loading && <IonLoading isOpen/>}
        </IonPage>
    );
};

export default Assembly;
