import {
    IonButton,
    IonCard,
    IonContent, IonFab, IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    useIonAlert
} from '@ionic/react';
import './Login.scss';
import {useCallback, useState} from "react";
import {useDispatch} from "react-redux";
import {authActions} from "../services/auth";
import CryptoJS from 'crypto-js';
import {Storage} from '@capacitor/storage';
import {useFetch} from "../hooks/use-fetch";
import {Device} from "@capacitor/device";
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from "@capacitor/push-notifications";
import {cogOutline} from "ionicons/icons";

const Login: React.FC = () => {
    const dispatch = useDispatch();
    const [present] = useIonAlert();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {loading, fetchData} = useFetch();

    const changeTestMode = () => {
        present({
            header: 'Verander API link',
            buttons: [
                {
                    text: 'Live',
                    handler: async () => {
                        dispatch(authActions.setTest(false));
                        await Storage.set({key: 'api', value: ''})
                    }
                },
                {
                    text: 'Test',
                    handler: async () => {
                        dispatch(authActions.setTest(true));
                        await Storage.set({key: 'api', value: 'test'});
                    }
                },
                {
                    text: 'Annuleren',
                    role: 'cancel'
                }
            ]
        });
    };

    const loginHandler = useCallback(async () => {
        const data = await fetchData('ZidaValidateUser', 'POST', JSON.stringify({
            username,
            password: CryptoJS.SHA512(password).toString(CryptoJS.enc.Hex)
        }));

        try {
            if (data.status !== 200) {
                throw new Error(data.status);
            }
            await Storage.set({key: 'user', value: JSON.stringify(data.data)});
            const api = await Storage.get({key: 'api'});
            if (api.value) {
                if (api.value === 'test') {
                    dispatch(authActions.setTest(true));
                }
            }
            dispatch(authActions.login());
            dispatch(authActions.setUser({token: data.data.token, role: data.data.data.role, id: data.data.data.id}));
            await checkPushNotifications(data.data.token)
        } catch (err) {
            console.error(err);
            dispatch(authActions.logout());
            present({
                header: 'Ongeldigde inloggegevens',
                message: 'Voer geldige inloggegevens in.',
                buttons: [
                    'Ok'
                ]
            });
        }
    }, [dispatch, fetchData, password, present, username]);

    const checkPushNotifications = async (token) => {
        const info = await Device.getInfo();
        if (info.platform === 'android') {
            // Request permission to use push notifications
            // Android will just grant without prompting
            PushNotifications.requestPermissions().then( result => {
                if (result.receive === 'granted') {
                    // Register with Apple / Google to receive push via APNS/FCM
                    PushNotifications.register();
                } else {
                    // Show some error
                }
            });

            // On success, we should be able to receive notifications
            PushNotifications.addListener('registration',
                (pushToken: Token) => {
                    fetchData('ZidaSaveRegistrationId', 'POST', JSON.stringify({token, registration_id: pushToken.value}));
                }
            );


            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError',
                (error: any) => {
                    console.log('Error on registration: ' + JSON.stringify(error));
                }
            );

            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived',
                (notification: PushNotificationSchema) => {
                    present({
                        message: notification.body,
                        buttons: [
                            {
                                text: 'Negeer',
                                role: 'cancel'
                            },
                            {
                                text: 'Gelezen',
                                handler: () => {
                                    fetchData('ZidaMarkMessageAsRead', 'POST', JSON.stringify({token, message_id: notification.data.message_id}));
                                }
                            }
                        ]
                    });
                }
            );

            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed',
                (notification: ActionPerformed) => {
                    console.log('Push action performed: ' + JSON.stringify(notification));
                }
            );
        }
    };

    return (
        <IonPage>
            <IonContent fullscreen>
                <div className="center-login">
                    <IonCard className="ion-padding">
                        <IonList>
                            <IonItem>
                                <IonLabel position="floating">Gebruikersnaam</IonLabel>
                                <IonInput type="text" value={username}
                                          onIonChange={(event) => setUsername(event.detail.value)}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Wachtwoord</IonLabel>
                                <IonInput type="password" value={password}
                                          onIonChange={(event) => setPassword(event.detail.value)}/>
                            </IonItem>
                            <IonButton expand="block" onClick={loginHandler}>Inloggen</IonButton>
                        </IonList>
                    </IonCard>
                </div>
                <IonFab horizontal="start" vertical="top">
                    <IonIcon icon={cogOutline} onClick={changeTestMode}/>
                </IonFab>
            </IonContent>
            {loading && <IonLoading isOpen/>}
        </IonPage>
    );
};

export default Login;
