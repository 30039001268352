import {useCallback, useState} from "react";
import {authActions} from "../services/auth";
import {Storage} from "@capacitor/storage";
import {useDispatch, useSelector} from "react-redux";
import {Network} from "@capacitor/network";
import {AuthState} from "../services/store";

export const useFetch = () => {
    const {token} = useSelector((state: AuthState) => state.auth.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const fetchData = useCallback(async (func: string, method?: string, details?) => {
        // Could be changed in Login.tsx, switches between 'test.' and '';
        const tmpApi = await Storage.get({key: 'api'});
        let api = '';

        if (tmpApi.value) {
            api = tmpApi.value;
        }

        setLoading(true);
        const response = await fetch(`https://www.${api ? api + '.' : ''}oes.zinkunie.nl/cms/api.php?type=frontend&function=${func}`, {
            method: method ? method : 'GET',
            body: details ? details : {},
            headers: {
                'Content-type': 'application/json'
            }
        });
        let data;
        if (response.status !== 200) {
            setLoading(false);
        } else {
            data = await response.json();
        }
        setLoading(false);

        if (data) {
            return data;
        } else {
            return response;
        }
    }, []);

    const checkStatus = useCallback(async (status) => {
        if (status === 401) {
            await dispatch(authActions.logout());
            await fetchData('ZidaRemoveRegistrationId', 'POST', JSON.stringify({token}));
        }
        if (status !== 200 && status !== 204) {
            throw new Error(status);
        }
    }, [dispatch, fetchData, token]);

    const tryData = useCallback(async (data) => {
        try {
            await checkStatus(data.status);
            let tmpData = await data.data;
            await Storage.set({key: 'data', value: JSON.stringify(tmpData)});
            return tmpData;
        } catch (err) {
            console.error(err);
        }
    }, [checkStatus]);

    const tryGeneral = useCallback(async (data) => {
        try {
            await checkStatus(data.status);
            if (data.status === 204) {
                const generalData = await Storage.get({key: 'general'});
                if (generalData.value) {
                    return JSON.parse(generalData.value);
                }
            } else {
                await Storage.set({key: 'last_modified_general', value: data.data.meta_data.last_modified});
            }
            let tmpData = await data.data.general;
            await Storage.set({key: 'general', value: JSON.stringify(tmpData)});
            return tmpData;
        } catch (err) {
            console.error(err);
        }
    }, [checkStatus]);

    const tryPrio = useCallback(async (data, prio) => {
        try {
            await checkStatus(data.status);
            if (data.status === 204) {
                const prioData = await Storage.get({key: 'prio-' + prio});
                if (prioData.value) {
                    return JSON.parse(prioData.value);
                }
            } else {
                await Storage.set({key: 'last_modified_prio-' + prio, value: data.data.meta_data.last_modified});
            }
            let tmpData = [];
            if (data.data && data.data.routes) {
                tmpData = await data.data.routes;
            }
            await Storage.set({key: 'prio-' + prio, value: JSON.stringify(tmpData)});
            return tmpData;
        } catch (err) {
            console.error(err);
        }
    }, [checkStatus]);

    const tryRoute = useCallback(async (data, route) => {
        try {
            await checkStatus(data.status);
            if (data.status === 204) {
                const routeData = await Storage.get({key: 'route-' + route});
                if (routeData.value) {
                    return JSON.parse(routeData.value);
                }
            } else {
                await Storage.set({key: 'last_modified_route-' + route, value: data.data.meta_data.last_modified});
            }
            let tmpData = await data.data;
            await Storage.set({key: 'route-' + route, value: JSON.stringify(tmpData)});
            return tmpData;
        } catch (err) {
            console.error(err);
        }
    }, [checkStatus]);

    const tryMessages = useCallback(async (data) => {
        try {
            await checkStatus(data.status);
            let tmpData = await data.data;
            await Storage.set({key: 'messages', value: JSON.stringify(tmpData)});
            return tmpData;
        } catch (err) {
            console.error(err);
        }
    }, [checkStatus]);

    const getGeneralData = useCallback(async (details?) => {
        let general;
        const net = await Network.getStatus();
        if (net.connected) {
            general = await fetchData('ZidaGetGeneralData', 'POST', details);
            return await tryGeneral(general);
        } else {
            const tmp = await Storage.get({key: 'general'});

            if (tmp.value) {
                general = await JSON.parse(tmp.value);
                return general;
            }
        }
    }, [fetchData, tryGeneral]);

    const getRouteData = useCallback(async (details?) => {
        let res;
        const net = await Network.getStatus();
        if (net.connected) {
            res = await fetchData('ZidaGetRouteData', 'POST', details);
            return await tryData(res);
        } else {
            const tmp = await Storage.get({key: 'data'});

            if (tmp.value) {
                res = await JSON.parse(tmp.value);
                return res;
            }
        }
    }, [fetchData, tryData]);

    const getPrioData = useCallback(async (details?) => {
        let res;
        const jsonDetails = await JSON.parse(details);
        const net = await Network.getStatus();
        if (net.connected) {
            res = await fetchData('ZidaGetPrioData', 'POST', details);
            return await tryPrio(res, jsonDetails.prio_number);
        } else {
            const prioData = await Storage.get({key: 'prio-'+jsonDetails.prio_number});

            if (prioData.value) {
                return await JSON.parse(prioData.value);
            }
        }
    }, [fetchData, tryPrio]);

    const collectRouteData = useCallback(async (details?) => {
        let res;
        const jsonDetails = await JSON.parse(details);
        const net = await Network.getStatus();
        if (net.connected) {
            res = await fetchData('ZidaCollectRouteData', 'POST', details);
            return await tryRoute(res, jsonDetails.route_number);
        } else {
            const routeData = await Storage.get({key: 'route-'+jsonDetails.route_number});

            if (routeData.value) {
                return await JSON.parse(routeData.value);
            }
        }
    }, [fetchData, tryRoute]);

    const getMessages = useCallback(async (details?) => {
        let data;
        const net = await Network.getStatus();
        if (net.connected) {
            data = await fetchData('ZidaGetMessages', 'POST', details);
            return await tryMessages(data);
        } else {
            const tmp = await Storage.get({key: 'messages'});

            if (tmp.value) {
                data = await JSON.parse(tmp.value);
                return data;
            }
        }
    }, [fetchData, tryMessages]);

    return {fetchData, loading, getGeneralData, getPrioData, collectRouteData, getRouteData, getMessages};
};
