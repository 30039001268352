import React, {useCallback, useEffect, useState} from "react";
import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api"
import {useSelector} from 'react-redux';
import {AuthState} from '../services/store';
import {useFetch} from '../hooks/use-fetch';

const containerStyle = {
    width: '100%',
    height: '300px'
};

let initialCenter = {
    lat: 52.1326,
    lng: 5.2913
};

const THE_NETHERLANDS_BOUNDS = {
    north: 53.5104033474,
    south: 50.803721015,
    west: 3.31497114423,
    east: 7.09205325687
};

const GoogleMaps = (props) => {
    const {token} = useSelector((state: AuthState) => state.auth.user);
    const {getRouteData} = useFetch();
    const {isLoaded} = useJsApiLoader({
        id: '',
        googleMapsApiKey: 'AIzaSyBz8qrPrElejucOPKQCMpSBmTysW1N0Uqs'
    });
    const [markers, setMarkers] = useState([]);
    const [center, setCenter] = useState(initialCenter);
    const [zoom, setZoom] = useState(6);
    const [map, setMap] = useState(null);

    useEffect(() => {
        if (props.markers) {
            setMarkers(props.markers);
        }
    }, [props])

    const onLoad = useCallback(async (map) => {
        const bounds = new google.maps.LatLngBounds();

        if (props.markers) {
            setMarkers(props.markers);

            if (props.markers.length === 0) {
                bounds.extend({lat: THE_NETHERLANDS_BOUNDS.north, lng: THE_NETHERLANDS_BOUNDS.west});
                bounds.extend({lat: THE_NETHERLANDS_BOUNDS.south, lng: THE_NETHERLANDS_BOUNDS.east});
            } if (props.markers.length === 1) {
                setZoom(14);
                setCenter({lat: +props.markers[0].lat, lng: +props.markers[0].lng});
            } else {
                for (const marker of props.markers) {
                    bounds.extend({lat: +marker.lat, lng: +marker.lng});
                }
                map.fitBounds(bounds);
            }
        } else {
            const {general} = await getRouteData(JSON.stringify({token}));

            if (general.maps_locations.length > 0) {
                setMarkers(general.maps_locations);
                for (const marker of general.maps_locations) {
                    bounds.extend({lat: +marker.lat, lng: +marker.lng});
                }
            } else {
                bounds.extend({lat: THE_NETHERLANDS_BOUNDS.north, lng: THE_NETHERLANDS_BOUNDS.west});
                bounds.extend({lat: THE_NETHERLANDS_BOUNDS.south, lng: THE_NETHERLANDS_BOUNDS.east});
            }

            map.fitBounds(bounds);
        }

        setMap(map);
    }, [props]);

    const onUnmount = useCallback((map) => {
        setMap(null);
        setMarkers([]);
    }, []);

    return isLoaded ? (
        <GoogleMap options={{disableDefaultUI: true, zoomControl: true}} mapContainerStyle={containerStyle} center={center} zoom={zoom} onLoad={onLoad} onUnmount={onUnmount}>
            {markers.map((marker, index) => (
                <Marker
                    options={{
                        optimized: true,
                        animation: google.maps.Animation.DROP
                    }}
                    label={{
                        color: marker.labelHex ? '#'+marker.labelHex : '#000000',
                        fontSize: '12px',
                        fontWeight: '600',
                        text: marker.number
                    }}
                    key={index}
                    icon={{
                        url: 'data:image/svg+xml;charset=utf-8,' +
                            encodeURIComponent(`<svg baseProfile="basic" xmlns="http://www.w3.org/2000/svg" stroke="${marker.labelHex ? '#'+marker.labelHex : '#333333'}" fill="${marker.hex ? '#'+marker.hex : '#f4f4f4'}" width="48" height="48" viewBox="0 0 48 48">
                                <path d="M48 19.7c-.3-13.2-7.9-18.5-8.3-18.7l-1.2-.8-1.2.8c-2 1.4-4.1 2-6.1 2-3.4 0-5.8-1.9-5.9-1.9l-1.3-1.1-1.3 1.1c-.1.1-2.5 1.9-5.9 1.9-2.1 0-4.1-.7-6.1-2l-1.3-.8-1.2.8c-.8.5-7.9 5.8-8.2 18.7-.1 1.1 3 22.2 24 28.3 22.9-6.7 24.1-26.9 24-28.3z"></path>                            
                            </svg>`)
                        ,
                        size: new google.maps.Size(28, 38),
                        scaledSize: new google.maps.Size(28, 28),
                        anchor: new google.maps.Point(14, 14),
                        labelOrigin: new google.maps.Point(14, 14)
                    }}
                    position={new google.maps.LatLng({lat: +marker.lat, lng: +marker.lng})}
                />
            ))}
        </GoogleMap>
    ) : <></>
};

export default GoogleMaps;
