import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonList,
    IonItem,
    IonBadge,
    IonLabel,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonLoading, IonCard, IonButton, useIonViewWillEnter
} from '@ionic/react';
import './Prio.scss';
import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {arrowBackOutline, cubeOutline, personOutline, pricetagsOutline, pulseOutline} from "ionicons/icons";
import {useFetch} from "../../hooks/use-fetch";
import {useSelector} from "react-redux";
import {AuthState} from "../../services/store";
import {Storage} from '@capacitor/storage';

const Prio: React.FC = () => {
    const {id}: any = useParams();
    const {token} = useSelector((state: AuthState) => state.auth.user);
    const [routeList, setRouteList] = useState([]);
    const [prioColor, setPrioColor] = useState('');
    const history = useHistory();
    const {loading, getPrioData} = useFetch();

    const getData = useCallback(async (event?) => {
        if (token) {
            const tmpModified = await Storage.get({key: 'last_modified_prio-'+id});

            const last_modified = +tmpModified.value;

            const general = await Storage.get({key: 'general'});
            const prioData = await getPrioData(JSON.stringify({token, prio_number: id, last_modified}));

            const jsonGeneral = await JSON.parse(general.value);
            if (jsonGeneral) {
                const {prio_data} = await jsonGeneral;
                const currentPrio = prio_data.find(item => id === item.prio_id);
                setPrioColor(currentPrio.prio_color);
            }
            if (prioData) {
                setRouteList(prioData);
            } else {
                return history.replace('/');
            }
        }

        if (event) {
            event.detail.complete();
        }
    }, [history, getPrioData, token, id]);

    const prevPage = () => {
        history.replace(`/`);
    };

    useIonViewWillEnter(() => {
        getData().then();
    });

    useEffect(() => {
        getData().then();
    }, [getData, id]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={prevPage}><IonIcon icon={arrowBackOutline} slot="icon-only"/></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonRefresher onIonRefresh={getData} slot="fixed">
                    <IonRefresherContent/>
                </IonRefresher>
                <div style={{background: prioColor, width: '100%', height: '5px'}}/>
                <IonList className="ion-no-padding">
                    {routeList.length === 0 && (
                      <IonItem lines="none">
                          <h3>Geen routes gevonden</h3>
                      </IonItem>
                    )}
                    {routeList.map((item) => (
                        <IonCard button key={item.id} routerLink={`/prio/${id}/${item.route}`}>
                            <IonItem lines="none">
                                <IonBadge slot="start" className="color-indicator color-indicator--505">
                                    {item.route}
                                </IonBadge>
                                <IonLabel>
                                    {item.owner &&
                                    <p><IonIcon className="ion-margin-end" icon={cubeOutline}/>{item.owner}</p>}
                                    <p><IonIcon className="ion-margin-end" icon={personOutline}/>{item.name}</p>
                                    <p><IonIcon className="ion-margin-end" icon={pricetagsOutline}/>{item.license_plate}
                                    </p>
                                </IonLabel>
                                <IonBadge slot="end"
                                          className={`color-indicator-prio color-indicator-prio--${item.status}`}>
                                    <IonIcon icon={pulseOutline}/>
                                </IonBadge>
                            </IonItem>
                        </IonCard>
                    ))}
                </IonList>
            </IonContent>
            {loading && <IonLoading isOpen/>}
        </IonPage>
    );
};

export default Prio;
