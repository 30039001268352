import {
  IonButton,
  IonButtons, IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonSegment,
  IonSegmentButton, IonSelect, IonSelectOption, IonTextarea,
  IonToolbar
} from '@ionic/react';
import React, {useEffect, useState} from "react";
import {closeOutline} from "ionicons/icons";
import {Storage} from '@capacitor/storage';

const IssueForm: React.FC<{onDismiss: () => void; data: {branchlist: [], order_id: '', articlenumber: '', rowKey: '', prevRemarks: []}}> = ({onDismiss, data}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [branch, setBranch] = useState("");
  const [remark, setRemark] = useState("");
  const [reason, setReason] = useState("wrong");

  const checkboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const submitIssue = async () => {
    const tmpRemarks = [];
    for (const item of data.prevRemarks) {
      tmpRemarks.push(item);
    }
    const tmpCurrent = tmpRemarks.find((item) => item.id === data.order_id + data.rowKey);

    if (quantity) {
      const newData = {id: data.order_id + data.rowKey, quantity, return_branch: branch, remarks: remark, reason_code: reason};
      if (tmpCurrent) {
        const index = tmpRemarks.indexOf(tmpCurrent);
        tmpRemarks[index] = newData;
        await Storage.set({key: 'article_remarks_key', value: JSON.stringify(tmpRemarks)});
      } else {
        await Storage.set({
          key: 'article_remarks_key',
          value: JSON.stringify([...tmpRemarks, newData])
        });
      }
    } else {
      if (tmpCurrent) {
        const index = tmpRemarks.indexOf(tmpCurrent);
        tmpRemarks.splice(index, 1);
        await Storage.set({key: 'article_remarks_key', value: JSON.stringify(tmpRemarks)});
      }
    }

    onDismiss();
  };

  useEffect(() => {
    const tmpRemark: any = data.prevRemarks.find((item: any) => item.id === data.order_id + data.rowKey);
    if (tmpRemark) {
      setReason(tmpRemark.reason_code);
      setQuantity(tmpRemark.quantity);
      setBranch(tmpRemark.return_branch);
      setRemark(tmpRemark.remarks);

      if (tmpRemark.return_branch) {
        setIsChecked(true);
      }
    }
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonItem lines="full" color="dark">
          <h3>{data.order_id}<IonNote> {data.articlenumber}</IonNote></h3>
        </IonItem>
        <IonSegment onIonChange={(event) => setReason(event.detail.value)} value={reason}>
          <IonSegmentButton value="wrong">
            <IonLabel>Verkeerd</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="damaged">
            <IonLabel>Beschadigd</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <IonList className="ion-no-padding">
          <IonItem>
            <IonLabel position={"stacked"}>Aantal</IonLabel>
            <IonInput onIonChange={(event) => setQuantity(parseInt(event.detail.value))} value={quantity} type={"number"} name="quantity"></IonInput>
          </IonItem>
          <IonItem lines={"full"}>
            <IonLabel>Mee teruggenomen?</IonLabel>
            <IonCheckbox onIonChange={(event) => checkboxChange(event)} checked={isChecked}></IonCheckbox>
          </IonItem>
          {isChecked && (
            <IonItem>
              <IonLabel position={"stacked"}>Filiaal</IonLabel>
              <IonSelect value={branch} onIonChange={(event) => setBranch(event.detail.value)} name={"return_branch"} placeholder={"Selecteer filiaal"} interface={"action-sheet"} cancelText="Annuleren">
                {data.branchlist.map((branch: any) => {
                  return <IonSelectOption key={branch.branch_code} value={branch.branch_code}>{branch.name_short}</IonSelectOption>;
                })}
              </IonSelect>
            </IonItem>
          )}
          <IonItem>
            <IonLabel position={"stacked"}>Opmerking</IonLabel>
            <IonTextarea onIonChange={(event) => setRemark(btoa(event.detail.value))} value={atob(remark)} name="remark"></IonTextarea>
          </IonItem>
          <IonButton onClick={submitIssue} color="light" expand="block">Verzenden</IonButton>
        </IonList>
      </IonContent>
    </IonPage>
  );
}



export default IssueForm;
