import {
    IonButton,
    IonButtons, IonContent, IonFooter,
    IonHeader, IonIcon, IonPage, IonSlide, IonSlides, IonText,
    IonTitle, IonToolbar
} from '@ionic/react';
import React from "react";
import './PhotoViewer.scss';
import {closeOutline} from "ionicons/icons";

const PhotoViewer: React.FC<{onDismiss: () => void; data: {src: '', title: '', description: '', color: false}}> = ({onDismiss, data}) => (
    <IonPage>
        <IonHeader>
            <IonToolbar color={data.color ? 'white' : 'dark'}>
                <IonButtons slot="start">
                    <IonButton onClick={onDismiss}>
                        <IonIcon slot="icon-only" icon={closeOutline}/>
                    </IonButton>
                </IonButtons>
                <IonTitle>{data.title}</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen color={data.color ? 'white' : 'dark'}>
            <IonSlides>
                <IonSlide>
                    <div className="swiper-zoom-container"><img src={data.src} style={{height: '100vh'}} alt="photoViewer"/></div>
                </IonSlide>
            </IonSlides>
        </IonContent>
        {data.description && (
            <IonFooter>
                <IonToolbar color={data.color ? 'white' : 'dark'} className="ion-text-center">
                    <IonText>{data.description}</IonText>
                </IonToolbar>
            </IonFooter>
        )}
    </IonPage>
);

export default PhotoViewer;
