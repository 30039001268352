import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonList,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonLoading,
    IonButton,
    IonTitle,
    useIonAlert,
    useIonViewWillEnter,
    useIonModal,
    IonItem,
    IonBadge,
    IonCard,
    IonLabel,
    IonReorderGroup, IonReorder, useIonToast, IonSelect, IonSelectOption
} from '@ionic/react';
import './DeliveryPlanner.scss';
import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router";
import {
    alarmOutline,
    arrowBackOutline,
    callOutline,
    chevronForwardOutline, constructOutline,
    listOutline,
    mapOutline,
    pinOutline, refreshCircleOutline, repeatOutline, timeOutline
} from "ionicons/icons";
import {useFetch} from "../../hooks/use-fetch";
import {useSelector} from "react-redux";
import {AuthState} from "../../services/store";
import {Storage} from "@capacitor/storage";
import KeyList from "../../modals/KeyList";
import GoogleMaps from "../../components/GoogleMaps";
import {Network} from "@capacitor/network";

const DeliveryPlanner: React.FC = () => {
    const {token} = useSelector((state: AuthState) => state.auth.user);
    const {loading, getRouteData, fetchData} = useFetch();
    const history = useHistory();
    const [addressList, setAddressList] = useState([]);
    const [routeList, setRouteList] = useState([]);
    const [routeOrder, setRouteOrder] = useState([]);
    const [reorderAble, setReorderAble] = useState(true);
    const [reorderActive, setReorderActive] = useState(false);
    const [mapOpen, setMapOpen] = useState(false);
    const [mapMarkers, setMapMarkers] = useState([]);
    const [general, setGeneral] = useState({delivery_timeframes: []});
    const [timeframes, setTimeframes] = useState([]);
    const [currentRoute, setCurrentRoute] = useState({
        route: null,
        delivery_route_confirmed: '0',
        key_info: [],
        delivery_data: []
    });
    const [present, dismiss] = useIonAlert();
    const [toast] = useIonToast();

    const handleDismiss = () => {
        dismissKeyModal();
    }

    const [keyModal, dismissKeyModal] = useIonModal(KeyList, {
        onDismiss: handleDismiss,
        list: currentRoute.key_info
    });

    const chooseRoute = useCallback((routes: any[]) => {
        present({
            header: 'Selecteer route',
            buttons: routes
        });
    }, [present]);

    const getData = useCallback(async (event?) => {
        const tmpRouteList = [];
        if (token) {
            const data = await getRouteData(JSON.stringify({token}));

            if (data.next_work_day) {
                for (const key in data.next_work_day) {
                    tmpRouteList.push({
                        text: 'Route ' + key, handler: async () => {
                            setCurrentRoute(data.next_work_day[key]);
                            await checkOrder(data.next_work_day[key]);
                            await Storage.set({key: 'next_route', value: key});
                            if (data.next_work_day[key].delivery_route_confirmed === '1') {
                                setReorderAble(false);
                            } else {
                                setReorderAble(true);
                            }
                        }
                    });
                }
                setRouteList(tmpRouteList);
                setGeneral(data.general);

                const tmpCur = await Storage.get({key: 'next_route'});

                if (tmpCur.value && data.next_work_day[tmpCur.value]) {
                    setCurrentRoute(data.next_work_day[tmpCur.value]);
                    await checkOrder(data.next_work_day[tmpCur.value]);
                    if (data.next_work_day[tmpCur.value].delivery_route_confirmed === '1') {
                        setReorderAble(false);
                    } else {
                        setReorderAble(true);
                    }
                } else if (tmpRouteList.length > 1) {
                    await dismiss();
                    chooseRoute(tmpRouteList);
                } else if (tmpRouteList.length === 1) {
                    setCurrentRoute(data.next_work_day[Object.keys(data.next_work_day)[0]]);
                    await checkOrder(data.next_work_day[Object.keys(data.next_work_day)[0]]);
                    if (data.next_work_day[Object.keys(data.next_work_day)[0]].delivery_route_confirmed === '1') {
                        setReorderAble(false);
                    } else {
                        setReorderAble(true);
                    }
                    await Storage.set({key: 'next_route', value: Object.keys(data.next_work_day)[0]});
                } else {
                    present('Geen routes gevonden');
                    setReorderAble(false);
                }
            } else {
                present('Geen routes gevonden');
                setReorderAble(false);
            }
        }

        if (event) {
            event.detail.complete();
        }
    }, [dismiss, chooseRoute, getRouteData, token, present]);

    const prevPage = () => {
        history.replace(`/`);
    };

    const checkOrder = async (current) => {
        let tmpOrder = [];
        const tmpRoute = [];
        const tmpKeys = await Storage.get({key: 'reorder-'+current.route});
        if (tmpKeys.value) {
            const keys = await JSON.parse(tmpKeys.value);

            tmpOrder = current.delivery_data;

            keys.map((key) => {
                tmpRoute.push(tmpOrder.find((customer) => customer.unique_key === key));
            });

            setAddressList(tmpRoute);
        } else {
            setAddressList(current.delivery_data);
        }
    }

    useEffect(() => {
        if (mapOpen) {
            setMapOpen(false);
        }
    }, [currentRoute]);

    useEffect(() => {
        if (addressList.length > 0) {
            const tmpRouteList = [];
            const tmpMapList = [];
            for (const customer of addressList) {
                tmpMapList.push({
                    number: (addressList.indexOf(customer) + 1).toString(),
                    lat: customer.orders[0].lat,
                    lng: customer.orders[0].lng,
                    label_hex:"FFFFFF",
                    hex:"ff0000",
                    route: currentRoute.route,
                    early: '0'
                });
                for (const order of customer.orders) {
                    tmpRouteList.push(order.levering_id);
                }
            }
            setRouteOrder(tmpRouteList);
            setMapMarkers(tmpMapList);
        }
    }, [addressList]);

    let getTimeout;

    useIonViewWillEnter(() => {
        clearTimeout(getTimeout);
        getTimeout = setTimeout(() => {
            getData().then();
        }, 200);
    });

    useEffect(() => {
        clearTimeout(getTimeout);
        getTimeout = setTimeout(() => {
            getData().then();
        }, 200);
    }, [getData]);

    const presentKeyInfo = async () => {
        keyModal();
    };

    const toggleReorder = async () => {
        if (reorderActive) {
            present({
                header: 'Nadat u bevestigt kunt u niets meer veranderen, weet u het zeker?',
                buttons: [
                    {
                        text: 'Nee',
                        handler: () => {
                            setReorderActive(false);
                        }
                    },
                    {
                        text: 'Ja',
                        handler: async () => {
                            const planner = await Storage.get({key: 'pending_planner'});
                            let plannerValue = [];
                            if (planner.value) {
                                plannerValue = JSON.parse(planner.value);
                            }

                            Network.getStatus().then(async (net) => {
                                setReorderActive(false);
                                setReorderAble(false);
                                await Storage.remove({key: 'reorder-'+currentRoute.route});
                                if (net.connected) {
                                    await fetchData('ZidaConfirmRouteList', 'POST', JSON.stringify({token, route_list: routeOrder, route: currentRoute.route, timeframes}));
                                } else {
                                    plannerValue.push({token, route_list: routeOrder, route: currentRoute.route, timeframes});
                                    await Storage.set({key: 'pending_planner', value: JSON.stringify(plannerValue)});
                                    await toast({
                                        message: 'Je bent offline, de data zal verzonden worden als je weer online gaat.',
                                        duration: 2000,
                                        color: 'warning'
                                    });
                                }
                            })
                        }
                    }
                ]
            });
        } else {
            setReorderActive(true);
        }
    };

    const callNumber = async (telNumber: string) => {
        window.open('tel:' + telNumber);
    };

    const reorderItems = async (event?) => {
        const tmpData = event.detail.complete(addressList);
        const tmpList = [];
        const tmpRoute = [];
        const tmpMarkerList = [];
        const tmpDraggingStorage = [];

        for (const customer of tmpData) {
            tmpDraggingStorage.push(customer.unique_key);
            tmpRoute.push(customer);
            tmpMarkerList.push({
                number: (tmpData.indexOf(customer) + 1).toString(),
                lat: customer.orders[0].lat,
                lng: customer.orders[0].lng,
                label_hex:"FFFFFF",
                hex:"ff0000",
                route: currentRoute.route,
                early: '0'
            });
            for (const order of customer.orders) {
                tmpList.push(order.levering_id);
            }
        }
        if (mapOpen) {
            setMapMarkers(tmpMarkerList);
        }

        await Storage.set({key: 'reorder-'+ currentRoute.route, value: JSON.stringify(tmpDraggingStorage)});
        setAddressList(tmpRoute);
        setRouteOrder(tmpList);

        event.detail.complete();
    };

    const toggleMap = () => {
        setMapOpen((prevMap) => !prevMap);
    };

    const changeTimeframe = async (event, unique_key, orders) => {
        let prevTimeframes = [];
        let newTimeframes = [];
        if (event.target.value) {
            prevTimeframes = timeframes;
            newTimeframes = [...prevTimeframes.filter((time) => time.unique_key !== unique_key), {unique_key: unique_key, orders, time: event.target.value}];
            setTimeframes(newTimeframes);
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={prevPage}><IonIcon icon={arrowBackOutline} slot="icon-only"/></IonButton>
                    </IonButtons>
                    <IonTitle>{currentRoute.route && `Route ${currentRoute.route}`}</IonTitle>
                    {(routeList.length > 0) && (
                        <IonButtons slot="end">
                            <IonButton onClick={toggleMap}><IonIcon slot="icon-only" icon={mapOutline}/></IonButton>
                            {routeList.length > 1 && (
                                <IonButton onClick={() => chooseRoute(routeList)}>Kies route</IonButton>
                            )}
                        </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {!reorderActive && !mapOpen && (
                    <IonRefresher onIonRefresh={getData} slot="fixed">
                        <IonRefresherContent />
                    </IonRefresher>
                )}
                {mapOpen && (
                    <GoogleMaps markers={mapMarkers}/>
                )}
                {reorderAble && addressList.length > 0 && (
                    <IonButton disabled={reorderActive && timeframes.length < addressList.length} expand="block" fill="clear" shape="round" onClick={() => toggleReorder()}>{reorderActive ? 'Bevestig' : 'Wijzig volgorde'}</IonButton>
                )}
                <IonList className="ion-no-padding">
                    {currentRoute.key_info.length > 0 && (
                        <IonItem lines="full" button onClick={presentKeyInfo} color="light">
                            <IonBadge slot="start" color="primary" className="color-indicator color-indicator--504"/>
                            <h3>Sleuteladressen</h3>
                            <IonIcon slot="end" icon={chevronForwardOutline}/>
                        </IonItem>
                    )}
                    {addressList.length === 0 && (
                        <IonItem>
                            <h3>Geen route data gevonden</h3>
                        </IonItem>
                    )}
                    {!reorderAble && addressList.length > 0 && (
                        <IonItem>
                            <h4>Ritlijst is reeds bevestigd</h4>
                        </IonItem>
                    )}
                    <IonReorderGroup disabled={!reorderActive} onIonItemReorder={reorderItems}>
                        {addressList.map((customer, index) => (
                            <IonCard key={customer.unique_key}>
                                <IonItem lines="none">
                                    <IonReorder slot="start" className="color-indicator color-indicator--502">
                                        <IonBadge className="color-indicator color-indicator--502">{index + 1}</IonBadge>
                                    </IonReorder>
                                    <h3>{customer.name}</h3>
                                    <IonButtons slot="end">
                                        {customer.orders[0].shipping.contact_phone && (
                                          <IonButton disabled={reorderActive} onClick={() => callNumber(customer.orders[0].shipping.contact_phone)}><IonIcon icon={callOutline}/></IonButton>
                                        )}
                                        <IonButton routerLink={`/tabs/delivery_planner/${customer.unique_key}`}><IonIcon icon={listOutline}/></IonButton>
                                    </IonButtons>
                                </IonItem>
                                <IonItem lines="none">
                                    <IonIcon slot="start" icon={pinOutline}/>
                                    <IonLabel>
                                        <p>{customer.address}</p>
                                        <p>{customer.postal_code} {customer.city}</p>
                                    </IonLabel>
                                    {customer.orders.length > 1 && <IonBadge>{customer.orders.length}</IonBadge>}
                                </IonItem>
                                {(customer.has_early === 1 || customer.has_refold === 1 || customer.has_return === 1 || customer.is_construction_site === 1 || customer.volume_data_html || customer.volume_forecast_html) && (
                                  <IonItem lines="none">
                                    {customer.has_early === 1 && <IonIcon icon={alarmOutline} color="danger" style={{marginRight: '10px'}}/>}
                                    {customer.has_refold === 1 && <IonIcon icon={repeatOutline} color="danger" style={{marginRight: '10px'}}/>}
                                    {customer.has_return === 1 && <IonIcon icon={refreshCircleOutline} color="danger" style={{marginRight: '10px'}}/>}
                                    {customer.is_construction_site === 1 && <IonIcon icon={constructOutline} color="danger" style={{marginRight: '10px'}}/>}
                                    {customer.volume_forecast_html && <span dangerouslySetInnerHTML={{__html: customer.volume_forecast_html}}></span>}
                                  </IonItem>
                                )}
                                {reorderActive && general.delivery_timeframes.length > 0 && (
                                  <IonItem>
                                      <IonIcon slot={"start"} icon={timeOutline}></IonIcon>
                                      <IonSelect style={{minWidth: "100%"}} onIonChange={(event) => changeTimeframe(event, customer.unique_key, customer.orders.map((order) => {return order.order_id}))} value={timeframes.find((time) => time.unique_key === customer.unique_key) ? timeframes.find((time) => time.unique_key === customer.unique_key).time : ''} placeholder={"Selecteer bezorgtijd"} interface={"action-sheet"} cancelText="Annuleren">
                                          {general.delivery_timeframes.map((time) => {
                                              return <IonSelectOption key={time.id} value={time.id}>{time.timeframe_title}</IonSelectOption>;
                                          })}
                                      </IonSelect>
                                  </IonItem>
                                )}
                            </IonCard>
                        ))}
                    </IonReorderGroup>
                </IonList>
            </IonContent>
            {loading && <IonLoading isOpen/>}
        </IonPage>
    );
};

export default DeliveryPlanner;
