import {
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonLabel,
    IonPage,
    IonRow,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonNote,
    IonList,
    IonItem,
    IonIcon,
    IonButton,
    IonLoading,
    IonSearchbar, IonModal, useIonToast, useIonViewWillEnter, useIonAlert
} from '@ionic/react';
import './Home.scss';
import {useCallback, useEffect, useState} from "react";
import {formatDate} from "../services/date";
import {closeOutline, eye, logInOutline, manOutline, refreshOutline} from "ionicons/icons";
import {useFetch} from "../hooks/use-fetch";
import {useSelector} from "react-redux";
import {AuthState} from "../services/store";
import {Network} from "@capacitor/network";
import GoogleMaps from "../components/GoogleMaps";
import {Storage} from '@capacitor/storage';

const Home: React.FC = () => {
    const {token, role} = useSelector((state: AuthState) => state.auth.user);
    const [prio, setPrio] = useState<any[]>([]);
    const [unreadMessages, setUnreadMessages] = useState([]);
    const [teamLeader, setTeamLeader] = useState(false);
    const [general, setGeneral] = useState({team_leader: '', maps_locations: []});
    const {fetchData, loading, getGeneralData, getRouteData, getMessages} = useFetch();
    const [search, setSearch] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [toast] = useIonToast();
    const [present] = useIonAlert();

    const presentToast = useCallback(async (message: string, color?: string) => {
        await toast({
            message,
            color: color ? color : '',
            duration: 2000
        })
    }, [toast]);

    const getData = useCallback(async () => {
        let generalData;

        const tmpModified = await Storage.get({key: 'last_modified_general'});
        const last_modified = +tmpModified.value;

        if (token) {
            if (role !== '*CHAUF') {
                generalData = await getGeneralData(JSON.stringify({token, last_modified}));
            }

            let {unread} = await getMessages(JSON.stringify({token}));

            if (unread) {
                setUnreadMessages(unread);
            }

            if (generalData) {
                setPrio(generalData.prio_data);
                setGeneral(generalData);
            }
        }
    }, [getGeneralData, getRouteData, getMessages, token, role]);

    useIonViewWillEnter(() => {
        setTimeout(() => {
            getData().then();
        }, 100);
    });

    useEffect(() => {
        setTimeout(() => {
            if (role === '*TLLAADP') {
                setTeamLeader(true);
            }
            getData().then();
        }, 100);
    }, [role, getData]);

    const markRead = async (message_id) => {
        const net = await Network.getStatus();
        if (net.connected) {
            await fetchData('ZidaMarkMessageAsRead', 'POST', JSON.stringify({token: token, message_id}));
            let {unread} = await getMessages(JSON.stringify({token}));

            await setUnreadMessages(unread);
        } else {
            await presentToast('Je bent offline, probeer het later opnieuw', 'warning');
        }
    };

    const presentLeaderShip = async () => {
        const net = await Network.getStatus();
        if (net.connected) {
            present({
                header: `${general.team_leader} is de teamleider, weet je zeker dat je de teamleider wilt worden?`,
                buttons: [
                    {
                        text: 'Nee',
                        role: 'cancel'
                    },
                    {
                        text: 'Ja',
                        handler: async () => {
                            await fetchData('ZidaSetTeamLeaderLoading', 'POST', JSON.stringify({token: token}));
                            await getData();
                        }
                    }
                ]
            });
        } else {
            await presentToast('Je bent offline, probeer het later opnieuw', 'warning');
        }
    };

    const chunk = 2;

    const prioData = prio.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunk);

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push({...item});

        return resultArray;
    }, []);

    const prioList = prioData.map((prioRow, index) => (
        <IonRow key={index}>
            {prioRow.map((prioItem) => (
                <IonCol key={prioItem.prio_id}>
                    <IonCard button routerLink={`/prio/${prioItem.prio_id}`} className="ion-padding prio"
                             style={{background: prioItem.prio_color}}>
                        <IonLabel>
                            Routes
                            <br/>
                            <IonNote>{prioItem.prio_name}</IonNote>
                        </IonLabel>
                    </IonCard>
                </IonCol>
            ))}
        </IonRow>
    ));

    useEffect(() => {
        let timeOut;
        if (searchValue.length >= 6) {
            timeOut = setTimeout(() => {
                fetchData('ZidaSearchOrder', 'POST', JSON.stringify({token, order_id: searchValue})).then(async (result) => {
                    if (result.data) {
                        setSearch(result.data);
                        setSearchValue("");
                    } else {
                        await presentToast(`Ongeldig ordernummer: ${searchValue}`, 'danger');
                    }
                });
            }, 500);
        }
        return () => {
            clearTimeout(timeOut);
        }
    }, [presentToast, fetchData, token, searchValue]);

    // @ts-ignore
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonSearchbar inputmode={"numeric"} placeholder={"Zoek order"} value={searchValue}
                                  onIonChange={e => setSearchValue(e.detail.value)}/>
                    <IonButtons slot="end">
                        <IonButton onClick={getData}>
                            <IonIcon slot="icon-only" icon={refreshOutline}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {role === '*CHAUF' && !loading && (
                    <GoogleMaps/>
                )}
                {prio.length > 0 && (
                    <IonGrid>
                        {prioList}
                    </IonGrid>
                )}
                {teamLeader && (
                    <IonCard>
                        <IonItem button onClick={presentLeaderShip} lines="none">
                            <IonIcon slot="start" icon={manOutline}/>
                            <IonLabel>
                                <p>{general.team_leader}</p>
                            </IonLabel>
                            <IonIcon icon={logInOutline} slot="end"/>
                        </IonItem>
                    </IonCard>
                )}
                {unreadMessages.length > 0 && (
                    <IonList className="ion-no-padding">
                        <IonItem>
                            <h3>Ongelezen berichten</h3>
                        </IonItem>
                        {unreadMessages.map((message) => (
                            <IonItem key={message.id}>
                                <IonLabel
                                    className="ion-text-wrap">{message.message}<br/><IonNote>{formatDate(message.created_on)}</IonNote></IonLabel>
                                <IonButton slot="end" fill="clear" color="medium" onClick={() => markRead(message.id)}>
                                    <IonIcon icon={eye} slot="icon-only"/>
                                </IonButton>
                            </IonItem>
                        ))}
                    </IonList>
                )}
            </IonContent>
            {loading && <IonLoading isOpen/>}
            {search && (
                <IonModal isOpen>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={() => setSearch(null)}>
                                <IonIcon icon={closeOutline} slot="icon-only"/>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonContent>
                        <IonGrid>
                            {search.map((item) => (
                                <IonRow key={item.value}>
                                    <IonCol>
                                        {item.label}
                                    </IonCol>
                                    <IonCol>
                                        <span dangerouslySetInnerHTML={{__html: item.value}}/>
                                    </IonCol>
                                </IonRow>
                            ))}
                        </IonGrid>
                    </IonContent>
                </IonModal>
            )}
        </IonPage>
    );
};

export default Home;
