import {Redirect, Route} from 'react-router-dom';
import {
    IonApp, IonFab, IonFabButton, IonIcon,
    IonRouterOutlet, useIonToast,
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import Home from './pages/Home';

import './App.scss';
import {useDispatch, useSelector} from "react-redux";
import {AuthState} from "./services/store";
import Login from "./pages/Login";
import React, {useCallback, useEffect, useState} from "react";
import Menu from "./components/Menu";
import Prio from "./pages/load/Prio";
import Messages from "./pages/Messages";
import {Storage} from "@capacitor/storage";
import {authActions} from "./services/auth";
import PrioRoute from "./pages/load/PrioRoute";
import {ConnectionStatus, Network} from "@capacitor/network";

import {wifi} from "ionicons/icons";
import PrioRouteDetail from "./pages/load/PrioRouteDetail";
import OrderEntry from "./pages/load/OrderEntry";
import Tabs from "./components/Tabs";
import {useFetch} from "./hooks/use-fetch";

const App: React.FC = () => {
    const {role} = useSelector((state: AuthState) => state.auth.user);
    const isAuth = useSelector((state: AuthState) => state.auth.isAuthenticated);
    const isTest = useSelector((state: AuthState) => state.auth.isTest);
    const dispatch = useDispatch();
    const [connected, setConnected] = useState(false);
    const [statusChanged, setStatusChanged] = useState<ConnectionStatus>();
    const [toast] = useIonToast();
    const {fetchData} = useFetch();

    useEffect(() => {
        Storage.get({key: 'user'}).then(async (res) => {
            if (res.value) {
                const {token, data} = JSON.parse(res.value);
                const {role, id} = data;
                const api = await Storage.get({key: 'api'});
                if (api.value) {
                    if (api.value === 'test') {
                        dispatch(authActions.setTest(true));
                    }
                }
                dispatch(authActions.setUser({token, role, id}));
                dispatch(authActions.login());
            }
        });
    }, [dispatch, isAuth]);

    useEffect(() => {
        Network.getStatus().then((res) => {
            setConnected(res.connected);
            if (!res.connected) {
                toast({
                    message: 'Je bent offline',
                    color: 'danger',
                    duration: 2000
                })
            }
        });
    }, [toast, statusChanged]);

    const checkPending = useCallback(async () => {
        const planner = await Storage.get({key: 'pending_planner'});
        const delivery = await Storage.get({key: 'pending_delivery'});

        Network.getStatus().then(async (net) => {
            if (net.connected) {
                if (planner.value) {
                    console.log(JSON.parse(planner.value));
                    const plannerValue = JSON.parse(planner.value);
                    for (const route of plannerValue) {
                        await fetchData('ZidaConfirmRouteList', 'POST', JSON.stringify({
                            token: route.token,
                            route_list: route.route_list,
                            route: route.route
                        }));
                    }
                    await Storage.remove({key: 'pending_planner'});
                    toast({
                        message: 'Alle offline opgeslagen data is verzonden!',
                        duration: 2000,
                        color: 'success'
                    });
                }
                if (delivery.value) {
                    const deliveryValue = JSON.parse(delivery.value);
                    for (const order of deliveryValue) {
                        await fetchData('ZidaConfirmDelivery', 'POST', JSON.stringify({
                            token: order.token,
                            order_id: order.order_id,
                            remarks: order.remarks,
                            images: order.images,
                            completed: order.completed
                        }));
                    }
                    await Storage.remove({key: 'pending_delivery'});
                    toast({
                        message: 'Alle offline opgeslagen data is verzonden!',
                        duration: 2000,
                        color: 'success'
                    });
                }
            }
        });
    }, [fetchData, toast]);

    useEffect(() => {
        Network.addListener('networkStatusChange', status => {
            setStatusChanged(status);
            checkPending().then();
        });
        return () => {
            Network.removeAllListeners().then();
        }
    }, [checkPending]);

    if (!isAuth) {
        return <Login/>;
    }

    return (
        <IonApp className={isTest ? 'testApp' : ''}>
            <IonReactRouter>
                <Menu/>
                <IonRouterOutlet id="main">
                    <Route exact path="/home">
                        <Home/>
                    </Route>
                    <Route exact path="/">
                        <Redirect to="/home"/>
                    </Route>
                    <Route exact path="/messages">
                        <Messages/>
                    </Route>
                    <Route exact path="/prio/:id">
                        <Prio/>
                    </Route>
                    <Route exact path="/prio/:id/:route">
                        <PrioRoute/>
                    </Route>
                    <Route exact path="/prio/:id/:route/:unique_key">
                        <PrioRouteDetail/>
                    </Route>
                    <Route exact path="/prio/:id/:route/:unique_key/:order_id">
                        <OrderEntry/>
                    </Route>
                </IonRouterOutlet>
                {role === '*CHAUF' && <Tabs/>}
            </IonReactRouter>
            {!connected && (
                <IonFab vertical="bottom" horizontal="end">
                    <IonFabButton color="light">
                        <IonIcon size="large" color="danger" icon={wifi}/>
                    </IonFabButton>
                </IonFab>
            )}
        </IonApp>
    );
}

export default App;
