import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonSelect, IonSelectOption, IonTextarea,
  IonToolbar
} from '@ionic/react';
import React, {useEffect, useState} from "react";
import {closeOutline} from "ionicons/icons";
import {Storage} from '@capacitor/storage';

const GeneralIssueForm: React.FC<{onDismiss: () => void; data: {title: '', order: '', branchlist: [], prevRemarks: []}}> = ({onDismiss, data}) => {
  const [branch, setBranch] = useState("");
  const [remark, setRemark] = useState("");
  const [reason, setReason] = useState("unordered_article");

  const submitIssue = async () => {
    const tmpRemarks = [];

    for (const item of data.prevRemarks) {
      tmpRemarks.push(item);
    }

    const tmpCurrent: any = data.prevRemarks.find((item: any) => item.order === data.order);

    if (remark) {
      const newData = {order: data.order, branch, remarks: remark, reason_code: reason};

      if (tmpCurrent) {
        const index = tmpRemarks.indexOf(tmpCurrent);
        tmpRemarks[index] = newData;
        await Storage.set({key: 'general_remarks_key', value: JSON.stringify(tmpRemarks)});
      } else {
        await Storage.set({
          key: 'general_remarks_key',
          value: JSON.stringify([...tmpRemarks, newData])
        });
      }
    } else {
      if (tmpCurrent) {
        const index = tmpRemarks.indexOf(tmpCurrent);
        tmpRemarks.splice(index, 1);
        await Storage.set({key: 'general_remarks_key', value: JSON.stringify(tmpRemarks)});
      }
    }

    onDismiss();
  };

  useEffect(() => {
    const tmpRemark: any = data.prevRemarks.find((item: any) => item.order === data.order);
    if (tmpRemark) {
      setReason(tmpRemark.reason_code);
      setBranch(tmpRemark.branch);
      setRemark(tmpRemark.remarks);
    }
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonItem lines="full" color="dark">
          <h3>{data.order} <IonNote>Onbesteld artikel</IonNote></h3>
        </IonItem>
        <IonList className="ion-no-padding">
          <IonItem>
            <IonLabel position={"stacked"}>Filiaal</IonLabel>
            <IonSelect value={branch} onIonChange={(event) => setBranch(event.detail.value)} name={"return_branch"} placeholder={"Selecteer filiaal"} interface={"action-sheet"} cancelText="Annuleren">
              {data.branchlist.map((branch: any) => {
                return <IonSelectOption key={branch.branch_code} value={branch.branch_code}>{branch.name_short}</IonSelectOption>;
              })}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position={"stacked"}>Opmerking</IonLabel>
            <IonTextarea onIonChange={(event) => setRemark(btoa(event.detail.value))} value={atob(remark)} name="remark"></IonTextarea>
          </IonItem>
          <IonButton onClick={submitIssue} color="light" expand="block">Verzenden</IonButton>
        </IonList>
      </IonContent>
    </IonPage>
  );
}



export default GeneralIssueForm;
