import {
    IonIcon, IonLabel, IonRouterOutlet,
    IonTabBar, IonTabButton,
    IonTabs,
} from '@ionic/react';

import './Tabs.scss';

import {carOutline, flagOutline} from 'ionicons/icons';
import {Redirect, Route} from "react-router-dom";
import Home from "../pages/Home";
import Messages from "../pages/Messages";
import React from "react";
import DeliveryToday from "../pages/delivery/DeliveryToday";
import DeliveryPlanner from "../pages/delivery/DeliveryPlanner";
import DeliveryDetail from "../pages/delivery/DeliveryDetail";
import Assembly from "../pages/delivery/Assembly";

const Tabs: React.FC = () => {

    return (
        <IonTabs>
            <IonRouterOutlet id="main">
                <Route exact path="/home">
                    <Home/>
                </Route>
                <Route exact path="/">
                    <Redirect to="/home"/>
                </Route>
                <Route exact path="/messages">
                    <Messages/>
                </Route>
                <Route exact path="/tabs/delivery_today">
                    <DeliveryToday/>
                </Route>
                <Route exact path="/tabs/delivery_today/:unique_key">
                    <DeliveryDetail/>
                </Route>
                <Route exact path="/tabs/delivery_today/:unique_key/:order_id">
                    <Assembly/>
                </Route>
                <Route exact path="/tabs/delivery_planner">
                    <DeliveryPlanner/>
                </Route>
                <Route exact path="/tabs/delivery_planner/:unique_key">
                    <DeliveryDetail/>
                </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
                <IonTabButton tab="today" href="/tabs/delivery_today">
                    <IonIcon icon={carOutline}/>
                    <IonLabel>Vandaag</IonLabel>
                </IonTabButton>
                <IonTabButton tab="tomorrow" href="/tabs/delivery_planner">
                    <IonIcon icon={flagOutline}/>
                    <IonLabel>Volgende leverdag</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    );
};

export default Tabs;
